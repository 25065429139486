@import '../../../styles/mixins';
@import '../../../styles/variables';


.Filter__options-container {
  @include tablet-md {
    position: relative;
    top: 8px; }
  min-width: 100%;
  position: absolute;
  top: 48px; }

.Filter__options {
  @include tablet-md {
    display: none; }
  background: $white;
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-12-percent, 0 3px 4px 0 $colorBlack-14-percent;
  min-width: 100%;
  padding: 16px;
  position: absolute;
  z-index: 6;

  &--header {
    color: $black;
    font-size: 16px;
    line-height: 1.5;
    padding-bottom: 14px;

    p {
      color: $colorGray1;
      font-size: 14px;
      line-height: 1.29; } } }
