@import '../../../styles/variables';
@import '../../../styles/mixins';
@import './mixins';


.InputWithUnits {
  @include text-input;
  @include tablet-md {
    max-width: 35vw; }

  align-items: center;
  display: flex;
  justify-content: space-between;

  &.InputWithUnits--active {
    border: solid 1px $colorSecondary3; } }

.InputWithUnits--units-right {
  flex-direction: row-reverse; }

.InputWithUnits__input {
  @include mobile-sm {
    min-width: 40px;
    width: 100%; }
  border: 0;
  border-radius: 4px;
  margin-left: 8px;
  min-width: 100px;

  &:focus {
    outline: none; } }

.InputWithUnits__units {
  margin-left: 8px; }
