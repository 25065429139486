@import '../../styles/mixins';
@import '../../styles/variables';


.FilterModal {
  background-color: $white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;

  &__header {
    align-content: center;
    display: flex;
    justify-content: space-between;
    padding: 32px 16px;
    width: 100%; }

  &__title {
    font-family: $fontBold;
    font-size: 16px;
    line-height: 1.5; }

  &__clear-all {
    color: $black;
    cursor: pointer;
    font-family: $fontRegular;
    font-size: 16px;
    height: 24px;
    line-height: 1.5;
    margin-right: 1em;

    &:hover {
      text-decoration: underline; } }

  &__close {
    fill-opacity: 0;
    height: 24px;
    stroke: $black;
    stroke-width: 1px;
    width: 24px;

    &:hover {
      cursor: pointer; } }

  .Filter__applied {
    display: none; }

  .Filter__name {
    display: block;

    &--open {
      background-color: $white;
      border: 0;
      color: $black;
      font-family: $fontBold;
      font-size: 14px; } }

  .Filter__options {
    @include tablet-sm {
      padding-left: 30px;
      padding-right: 30px; }
    box-shadow: none;
    display: block;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 0;
    position: relative;
    z-index: auto;

    &-container {
      margin: 0; } }

  &__body {
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll; }

  &__footer {
    align-content: center;
    background-color: $colorSecondary3;
    display: flex;
    justify-content: center;
    padding: 16px; } }
