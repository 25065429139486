body {
  max-width: 100vw;
  margin: 0;
  font-family: "Axiforma-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.overflow-hidden {
  overflow: hidden;
}

code {
  font-family: "Axiforma-Regular", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
