@import './variables';

$breakpoint-mobile-xs: 320px;
$breakpoint-mobile-sm: 420px;
$breakpoint-tablet-xs: 600px;
$breakpoint-tablet-sm: 768px;
$breakpoint-tablet-md: 1024px;
$breakpoint-desktop-sm: 1440px;

@mixin ie11-styles {
  // sass-lint:disable no-vendor-prefixes
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    @content; } }

@mixin mobile-xs {
  @media screen and (max-width: $breakpoint-mobile-xs) {
    @content; } }

@mixin mobile-sm {
  @media screen and (max-width: $breakpoint-mobile-sm) {
    @content; } }

@mixin tablet-xs {
  @media screen and (max-width: $breakpoint-tablet-xs) {
    @content; } }

@mixin tablet-sm {
  @media screen and (max-width: $breakpoint-tablet-sm) {
    @content; } }

@mixin tablet-md {
  @media screen and (max-width: $breakpoint-tablet-md) {
    @content; } }

@mixin desktop-sm {
  @media screen and (max-width: $breakpoint-desktop-sm) {
    @content; } }

@mixin font-style($size, $line-height, $color: $black, $family: $fontRegular) {
  color: $color;
  font-family: $family;
  font-size: $size;
  line-height: $line-height; }

@mixin menu-title-underscore($color) {
  &::after {
    background: $color;
    border-radius: 3px;
    content: '';
    float: left;
    height: 6px;
    width: 100%; } }
