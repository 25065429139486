@import '../../../styles/variables';
@import '../../../styles/mixins';


.ChartHeader {
  @include mobile-sm {
    padding: 0 10px; }
  margin-bottom: 1em;
  padding: 0 20px;
  width: 100%;

  &__title {
    @include tablet-sm {
      font-size: 16px; }
    @include tablet-xs {
      font-size: 14px; }
    font-size: 20px;
    line-height: 1.5;
    padding: 10px 0; }

  &__subtitle {
    @include tablet-md {
      font-size: 14px; }
    @include tablet-xs {
      font-size: 12px; }
    color: $colorGray1;
    font-size: 14px;
    line-height: 1.71; } }
