@import '../../../styles/mixins';
@import '../../../styles/variables';


.ViewSlider {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  z-index: 0;

  &--districts {
    .ViewSlider__indicator {
      width: 120px; } }

  &--students {
    .ViewSlider__indicator {
      left: calc(100%);
      transform: translateX(-100%);
      width: 90px; } } }

.ViewSlider__labels {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: normal;
  justify-content: space-between;
  text-align: center; }

.ViewSlider__label {
  padding-bottom: 10px;
  z-index: 15;

  &--on {
    font-weight: bold; }

  &--off {
    font-weight: normal; } }

.ViewSlider__slider {
  align-items: center;
  background: $colorLightGray3;
  border-radius: 12.5px;
  bottom: 10px;
  display: flex;
  height: 5px;
  justify-content: space-between;
  position: relative;
  width: 220px;

  .ViewSlider__indicator {
    background: $colorSecondary3;
    border-radius: 12.5px;
    box-shadow: 0 0 2px 0 $colorBlack-38-percent;
    content: '';
    height: 5px;
    position: absolute; } }
