@import '../../../styles/variables';
@import '../../../styles/mixins';


// sass-lint:disable no-vendor-prefixes
.ErateAppliedFunding {
  @include ie11-styles {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 2;
    margin: 7px;
    width: 35vw; }
  @include tablet-md {
    @include ie11-styles {
      -ms-grid-column: 3;
      -ms-grid-column-span: 3;
      -ms-grid-row: 2;
      width: 55vw; }
    grid-column: 3 / span 4;
    grid-row: 2; }
  @include tablet-xs {
    grid-column: 1 / span 2;
    grid-row: 3;
    margin: 15px 0; }
  align-items: center;
  background-color: $white;
  box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-12-percent, 0 3px 4px 0 $colorBlack-14-percent;
  display: flex;
  flex-flow: column;
  grid-column: 1 / span 3;
  grid-row: 2;

  &__content-container {
    display: flex;
    flex-flow: column;
    width: 100%; }

  &__expand {
    @include tablet-md {
      @include ie11-styles {
        -ms-grid-column: 1;
        -ms-grid-column-span: 6; }
      grid-column: 1 / span 6; } }

  &__chart {
    @include tablet-md {
      @include ie11-styles {
        width: 44vw; } }
    @include tablet-sm {
      @include ie11-styles {
        width: 38vw; } }
    @include tablet-xs {
      @include ie11-styles {
        width: 38vw; }
      height: 30vh; }
    height: 20vh;
    padding: 10px 20px;
    position: relative;
    width: 100%; }

  &__button {
    align-self: center;
    margin-bottom: .85em; } }
