@import '../../../styles/variables';

.ThankYou {
  border-bottom: 1px solid $colorGray3;
  margin-bottom: 2rem;

  &__title {
    padding-bottom: 1rem; }

  &__subtitle {
    padding-bottom: 3rem; } }
