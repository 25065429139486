@import '../../../styles/variables';
@import '../../../styles/mixins';


.StateOverviewPDFButton {
  @include mobile-sm {
    margin: 17px 0;
    padding: 0 5px; }

  border-radius: 8px;
  display: inline-block;
  height: 40px;
  justify-content: center;
  margin: 17px;
  padding: 0 16px;
  vertical-align: middle;
  width: auto;

  &__loading {
    background-color: $colorGray2;

    &:hover {
      cursor: default; } }

  &__loading-spinner {
    @include ie11-styles {
      height: 30px;
      padding-top: 10px; }
    padding-right: 2px; }

  &__active {
    background-color: $colorSecondary3;

    &:hover {
      box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-12-percent, 0 3px 4px 0 $colorBlack-14-percent;
      cursor: pointer;
      text-decoration: none; } } }
