@import '../../../styles/variables';
@import '../../../styles/mixins';


.SliderHandle {
  align-items: center;
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0;
  z-index: 2;

  &:hover {
    cursor: pointer; } }

.SliderHandle__icon {
  background-color: $white;
  border: solid 1px $colorSecondary3;
  border-radius: 50%;
  height: 24px;
  width: 24px; }

.SliderHandle__icon,
.SliderHandle__label {
  // sass-lint:disable no-vendor-prefixes
  @include ie11-styles {
    top: 8px; }
  left: -50%;
  position: relative; }

.SliderHandle__label {
  white-space: nowrap; }
