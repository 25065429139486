@import '../../styles/variables';
@import '../../styles/mixins';


.App {
  background-image: linear-gradient(to bottom, $colorLightGray4, $colorLightGray5), linear-gradient(to bottom, $colorLightGray1, $colorLightGray1);
  display: flex;
  flex: 1 1;
  flex-flow: column;
  font-size: calc(10px + 1vmin);
  height: 100%;
  min-height: 100vh;
  position: relative;
  text-align: left;

  &--map {
    @include tablet-md {
      height: 100%; }
    height: 100vh; }

  > * {
    color: $black;
    font-family: $fontRegular;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.29; } }
