@import '../../../styles/mixins';
@import '../../../styles/variables';


.Filter__actions {
  @include tablet-md {
    display: none; }
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0; }

.Filter__action {
  margin: 0;
  padding: 0;
  text-transform: capitalize;

  &:hover {
    cursor: pointer; } }

.Filter__action--clear {
  align-self: flex-start; }

.Filter__action--apply {
  align-self: flex-end;
  color: $colorSecondary3; }
