@import '../../../styles/variables';
@import '../../../styles/mixins';


.DistrictServicesModal {
  display: none;

  &__backdrop {
    background-color: $colorBlack-38-percent;
    height: 100%;
    position: fixed;
    width: 100%; }

  &__content {
    @include tablet-md {
      max-width: 95%; }
    @include mobile-xs {
      padding: 10px; }
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 11px 15px -7px $colorBlack-20-percent, 0 9px 46px 8px $colorBlack-12-percent, 0 24px 38px 3px $colorBlack-14-percent;
    font-size: 12px;
    margin: 0 auto;
    max-height: 500px;
    max-width: 58vw;
    overflow: scroll;
    padding: 30px;
    position: relative;
    top: 70px;
    width: fit-content; }

  &__header {
    display: flex;
    justify-content: space-between; }

  &__footer {
    font-size: 12px;

    img {
      vertical-align: text-top; } }

  &__title {
    color: $black;
    font-size: 22px;
    min-height: 29px; }

  &__close-button {
    height: 24px;
    width: 24px;

    &:hover {
      cursor: pointer; } }

  &--open {
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100000; }


  &__tooltip--content {
    font-size: 12px;
    z-index: 100001;

    .popover-body {
      max-width: 220px; } }

  .Table__container {
    height: auto; } }

.UnfitMessage {
  background-color: $white;
  padding-top: 8px;

  a {
    color: $colorSecondary3;

    &:hover {
      color: $colorSecondary3; } }

  &--dirty-services {
    display: flex;
    flex-direction: row;
    padding-bottom: 4px; }

  &__icon--dirty-services {
    padding-right: .5em; }

  &__text--dirty-services {
    color: $black;
    font-family: $fontBold;
    font-size: 14px;
    margin: 0;
    padding-top: 1.5px; }

  &__icon--no-services {
    @include tablet-md {
      padding-left: 0;
      width: 25%; }
    @include mobile-sm {
      padding: 14px;
      width: 78%; }
    display: inline-block;
    float: left;
    padding-left: 15%;
    padding-right: 1.2em; }

  &__text--no-services {
    @include tablet-md {
      font-size: 18px;
      max-width: 62%; }
    @include mobile-sm {
      max-width: 85%; }
    color: $black;
    font-size: 21px;
    max-width: 85%; } }


@include mobile-sm {
  .DistrictServicesModal {
    &__backdrop {
      display: none; }

    &__content {
      bottom: 0;
      height: 100%;
      left: 0;
      margin: 0;
      max-height: initial;
      max-width: 100vw;
      position: fixed;
      right: 0;
      top: 0;
      width: fit-content; }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block; }

    thead {
      tr {
        left: -9999px;
        position: absolute;
        top: -9999px; } }

    .table {
      thead,
      tbody {
        td {
          @include mobile-xs {
            min-height: 60px; }
          border: 0;
          padding-left: 50%;
          position: relative; } } }

    .table {
      tfoot {
        td {
          padding-left: 0; } } }

    tbody {
      tr {
        padding-bottom: 10px; }

      td {
        &::before {
          color: $black;
          left: 6px;
          padding: 5px 15px 0;
          position: absolute;
          text-align: right;
          top: 6px;
          width: 45%; } }

      td {
        &:nth-of-type(1) {
          &::before {
            content: 'Internet Provider:'; } } }

      td {
        &:nth-of-type(2) {
          &::before {
            content: 'Circuit Size:'; } } }

      td {
        &:nth-of-type(3) {
          &::before {
            content: 'Connections:'; } } }

      td {
        &:nth-of-type(4) {
          &::before {
            content: 'Monthly Cost/ Connection:'; } } }

      td {
        &:nth-of-type(5) {
          &::before {
            content: 'Purpose:'; } } }

      td {
        &:nth-of-type(6) {
          &::before {
            content: 'Contract End Date:'; } } } } } }
