@import '../../../../styles/variables';
@import '../../../../styles/mixins';


.PurposeFilter {
  min-width: 240px;

  &__header {
    margin-bottom: 10px; } }


.PurposeFilter__modal__tooltip {
  @include tablet-md {
    display: inline-block;
    margin: 0 5px; }
  display: none; }

.PurposeFilter__header__tooltip {
  height: 18px;
  margin: 0 3px; }

.PurposeFilter__aria-label {
  display: none; }

.PurposeFilter__label {
  align-items: center;
  display: flex;

  &:hover {
    cursor: pointer; }

  &--text {
    font-size: 14px; } }

.PurposeFilter__checkbox--input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.PurposeFilter__checkbox-icon {
  border-radius: 4px;
  display: block;
  font-size: 24px; }

.PurposeFilter__checkbox-icon--checked {
  color: $colorSecondary3;
  stroke: $colorSecondary3; }

.PurposeFilter__checkbox-icon--unchecked {
  color: $white;
  stroke: $black;
  stroke-width: 14px; }

.PurposeFilter__label--text {
  margin-left: 5px; }
