@import '../../../styles/variables';
@import './mixins';
@import '../../../styles/mixins';


.MapLegend {
  &__container {
    @include tablet-md {
      margin: 20px 0 0 -5px; }
    @include mobile-xs {
      margin: 0 0 0 -6px; }
    background: $white;
    display: flex;
    line-height: 30px;
    margin: 0 -5px;
    width: 100%; }

  &__key {
    @include tablet-sm {
      margin: 0; }
    margin: 0 5px 0 0;

    > span {
      bottom: 3px;
      display: inline-block;
      font-size: 10px;
      height: 18px;
      line-height: 1.8;
      position: relative; } }

  &--no-data-color {
    @include legend--quartile-color($colorGray3); }

  &--first-quartile-color {
    @include legend--quartile-color($colorNationalMapBlue1); }

  &--second-quartile-color {
    @include legend--quartile-color($colorNationalMapBlue2); }

  &--third-quartile-color {
    @include legend--quartile-color($colorNationalMapBlue3); }

  &--fourth-quartile-color {
    @include legend--quartile-color($colorNationalMapBlue4); }

  &--last-quartile-color {
    @include legend--quartile-color($colorNationalMapBlue5); } }
