@import '../../../styles/variables';

@mixin legend--quartile-color($color) {
  @include mobile-xs {
    margin: 0 2px; }
  background-color: $color;
  border: solid 1px $white;
  border-radius: 4px;
  display: inline-block;
  height: 16px;
  margin: 0 5px;
  object-fit: contain;
  width: 16px; }
