@import '../../styles/mixins';
@import '../../styles/variables';


.FilterModal__Button {
  @include tablet-md {
    display: flex; }
  @include mobile-sm {
    margin: 10px 10px 10px 0; }
  align-items: center;
  background-color: $white;
  border: solid 1px $colorPrimary-60-percent;
  border-radius: 8px;
  color: $black;
  display: none;
  font-family: $fontBold;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  margin: 0 16px;
  width: 100px;

  &:hover {
    cursor: pointer; }

  &--save {
    align-content: center;
    border: 0;
    display: flex;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 208px; }

  &--count {
    align-items: center;
    background-color: $colorPrimary-60-percent;
    border-radius: 50%;
    color: $white;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    height: 18px;
    justify-content: center;
    line-height: 1.5;
    margin-left: 5px;
    padding: .5em;
    width: 18px; } }

.FilterModal__ButtonIcon {
  height: 24px;
  width: 24px; }
