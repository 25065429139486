// NOTE: There's an issue importing this file into a .js or .jsx file without the
// .scss extension.

// Fonts
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css');

@font-face {
  font-family: 'Axiforma-Bold';
  src: url('../assets/fonts/Axiforma-Bold/font.woff2') format('woff2'), url('../assets/fonts/Axiforma-Bold/font.woff') format('woff');
}
@font-face {
  font-family: 'Axiforma-Regular';
  src: url('../assets/fonts/Axiforma-Regular/font.woff2') format('woff2'), url('../assets/fonts/Axiforma-Regular/font.woff') format('woff');
}

$fontRegular: 'Axiforma-Regular', sans-serif;
$fontBold: 'Axiforma-Bold', sans-serif;
$font-awesome: 'FontAwesome';
$navbar-height: 72px;

// Colors
$transparent: rgba(0, 0, 0, 0);
$black: #222;
$white: #fff;
$grey: rgba(58, 73, 116, .3);
$ecru: #fbfbfb;
$colorBlack-80-percent: rgba(0, 0, 0, .8);
$colorBlack-60-percent: rgba(0, 0, 0, .6);
$colorBlack-38-percent: rgba(0, 0, 0, .38);
$colorBlack-32-percent: rgba(0, 0, 0, .32);
$colorBlack-20-percent: rgba(0, 0, 0, .2);
$colorBlack-12-percent: rgba(0, 0, 0, .12);
$colorBlack-14-percent: rgba(0, 0, 0, .14);
$colorWhite-0-percent: rgba(255, 255, 255, 0);
$colorWhite-100-percent: rgba(255, 255, 255, 1);
$colorPrimary: #fb010d;
$colorPrimary-80-percent: #fc343d;
$colorPrimary-60-percent: #fd676e;
$colorSecondary1: #ac1f23;
$colorSecondary2: #042144;
$colorSecondary3: #007ec6;
$colorSecondary3-10-percent: #e5f2f9;
$colorSecondary3-20-percent: #cce5f4;
$colorSecondary3-40-percent: #99cbe8;
$colorSecondary3-60-percent: #66b2dd;
$colorSecondary6: #cb2027;
$colorSecondary6-20-percent: #f5d2d4;
$colorTertiary1: #004f77;
$colorLightGray1: #ebebeb;
$colorLightGray2: #f6f6f6;
$colorLightGray3: #dee2e6;
$colorLightGray4: #fdfbfb;
$colorLightGray5: #ebedee;
$colorGray1: #4a4a4a;
$colorGray2: #8a8a8a;
$colorGray3: #dfdfdf;
$colorChartColor1: #fd676e;
$colorChartColor2: #9d4b82;
$colorChartColor3: #039be5;
$colorNationalMapBlue1: #d1ecfa;
$colorNationalMapBlue2: #76c8f0;
$colorNationalMapBlue3: #039ce5;
$colorNationalMapBlue4: #02557e;
$colorNationalMapBlue5: #000f16;
$skeletonBaseColor: #eee;
$skeletonHighlightColor: #f5f5f5;
$newsletterGray: #d2d7df;
$newsletterDarkGray: #5d5d65;
$newsletterParagraph: #434d5d;
$newsletterTextboxShadow: rgba(142, 154, 173, .1);
$newsletterTextboxFocusInner: #509cf6;
$newsletterTextboxFocusOuter: rgba(80, 156, 246, .25);
$newsletterGreenButton: #7bb13d;

// sass-lint:disable no-misspelled-properties
:export {
  black: $black;
  colorBlack12percent: $colorBlack-12-percent;
  colorChartColor1: $colorChartColor1;
  colorChartColor2: $colorChartColor2;
  colorChartColor3: $colorChartColor3;
  colorGray1: $colorGray1;
  colorGray2: $colorGray2;
  colorGray3: $colorGray3;
  colorLightGray1: $colorLightGray1;
  colorLightGray2: $colorLightGray2;
  colorNationalMapBlue1: $colorNationalMapBlue1;
  colorNationalMapBlue2: $colorNationalMapBlue2;
  colorNationalMapBlue3: $colorNationalMapBlue3;
  colorNationalMapBlue4: $colorNationalMapBlue4;
  colorNationalMapBlue5: $colorNationalMapBlue5;
  colorPrimary: $colorPrimary;
  colorSecondary2: $colorSecondary2;
  colorSecondary3: $colorSecondary3;
  colorSecondary310Percent: $colorSecondary3-10-percent;
  colorSecondary340Percent: $colorSecondary3-40-percent;
  colorTertiary1: $colorTertiary1;
  fontAwesome: $font-awesome;
  fontBold: $fontBold;
  fontRegular: $fontRegular;
  navbarHeight: $navbar-height;
  transparent: $transparent;
  white: $white;
};
