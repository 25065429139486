@import '../../../styles/variables';
@import '../../../styles/mixins';


.StatePopup {
  @include mobile-sm {
    display: none; }
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 $colorBlack-20-percent, 0 3px 1px -2px $colorBlack-12-percent, 0 2px 2px 0 $colorBlack-14-percent;
  max-height: 125px;
  position: absolute;
  width: 220px;
  z-index: 3;

  &__content {
    padding: 8px; }

  &__header {
    background-color: $colorLightGray2;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 14px;
    height: 32px;
    padding: 8px; }

  &__text {
    font-size: 14px; }

  &__link {
    padding-top: 16px;

    > a {
      color: $colorSecondary3;
      font-size: 12px; } }

  &__icon {
    height: 24px;
    margin: 0 4px 4px 0; } }
