@import '../../../styles/mixins';
@import '../../../styles/variables';


.RangeFilter__Slider {
  @include tablet-md {
    margin: 0 4vw 2vw; }
  @include mobile-sm {
    margin: 0 32px 16px; }
  align-items: center;
  display: flex;
  height: 24px;
  margin: 0 40px 16px;
  max-width: 100%;
  min-width: 160px;
  position: relative; }

.RangeFilter__Rail {
  background-color: $colorGray2;
  border-radius: 4px;
  cursor: pointer;
  height: 16px;
  position: absolute;
  width: 100%; }

.RangeFilter__Tracks {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  top: 0; }
