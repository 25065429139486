@import '../../../styles/mixins';
@import '../../../styles/variables';


.DistrictsMap {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;

  &__container {
    overflow: hidden;
    position: relative;
    width: 50%; }

  &__skeleton {
    height: 100%;
    opacity: .8;
    position: absolute;
    width: 100%;
    z-index: 2; }

  &__loading-spinner {
    height: 80px;
    left: 46%;
    opacity: .5;
    position: absolute;
    top: 40%;
    width: 80px; }

  &__marker {
    cursor: pointer; }

  .Table__title,
  .Table__title--subtitle {
    font-weight: 400;
    margin-bottom: 0; } }

.DistrictsMap__title {
  flex: 0 1 auto; }
