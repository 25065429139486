@import '../../../styles/mixins';

.RangeInputGroup {
  @include tablet-md {
    margin-left: 0; }
  @include mobile-sm {
    width: 100%; }
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 40px 16px; }

.RangeInputGroup__separator {
  margin: 0 8px; }
