@import '../../styles/variables';
@import '../../styles/mixins';

// sass-lint:disable nesting-depth

.FilterPane {
  @include tablet-md {
    padding: 10px 0 0 16px; }
  @include mobile-sm {
    position: inherit; }
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  padding: 15px 0 17px 25px;
  position: sticky;
  top: 0;
  z-index: 50;

  &__title {
    @include tablet-md {
      flex-basis: auto;
      margin: 0 16px 0 0;
      width: auto; }
    align-items: center;
    display: flex;
    font-family: $fontBold;
    font-size: 14px;
    margin: 0 16px 12px 0;
    white-space: nowrap;

    &-anchor {
      &::after {
        @include tablet-md {
          content: 'Districts filtered by:'; }
        content: 'Filter districts by:';
        flex: 0 0 100%;
        margin-right: 7px; } } }

  &__filters {
    @include tablet-md {
      display: none; }
    align-items: center;
    display: flex;
    flex-wrap: wrap; }

  &__clear-all {
    color: $colorSecondary3;
    cursor: pointer;
    font-family: $fontRegular;
    font-size: 14px;
    height: 24px;
    line-height: 1.71;
    margin: 10px 0;

    &:hover {
      text-decoration: underline; } } }
