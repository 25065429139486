@import '../../../styles/variables';
@import '../../../styles/mixins';


.MenuButton {
  @include mobile-sm {
    padding: 0;
    width: 9vh; }
  @include mobile-xs {
    width: 10vh; }
  background-color: $white;
  border: solid 1px $colorSecondary3;
  border-radius: 8px;
  height: 100px;
  margin: .75rem 0;
  width: 100px;

  &:hover {
    @include tablet-md {
      background-color: $colorSecondary3-40-percent; }
    background-color: $colorSecondary3-10-percent; }

  &--selected {
    background-color: $colorSecondary3-40-percent; }

  &__img {
    @include mobile-sm {
      height: 2.5rem; } }

  &__text {
    @include mobile-sm {
      font-size: calc(8px + 1vmin); }
    @include mobile-xs {
      font-size: calc(7px + 1vmin); }
    font-family: $fontBold;
    font-size: 14px; } }
