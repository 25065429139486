@import '../../../styles/variables';
@import '../../../styles/mixins';


// sass-lint:disable no-vendor-prefixes
.PercentMeetingGoal {
  @include ie11-styles {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 1;
    display: flex;
    margin: 7px;
    width: 42vw; }
  @include tablet-md {
    @include ie11-styles {
      -ms-grid-column-span: 4;
      width: 50vw; }
    grid-column: 1 / span 4;
    grid-row: 1; }
  @include mobile-sm {
    @include ie11-styles {
      padding: 10px 0; }
    margin: 0 0 15px; }
  background-color: $white;
  box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-12-percent, 0 3px 4px 0 $colorBlack-14-percent;
  display: flex;
  flex-flow: row wrap;
  grid-column: 1 / span 4;
  grid-row: 1;

  &__content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%; }

  &__expand {
    @include ie11-styles {
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      width: 65vw; }
    @include tablet-md {
      @include ie11-styles {
        -ms-grid-column-span: 6;
        width: 92.5vw; } }
    grid-column: 1 / span 6; }

  &__chart {
    @include ie11-styles {
      height: 40vh;
      width: auto; }
    @include tablet-md {
      height: 32vh;
      padding: 0 10px; }
    height: 30vh;
    padding: 0 20px;
    position: relative;
    width: auto; }

  &__button {
    align-self: center;
    margin-bottom: .85em; } }
