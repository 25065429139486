@import '../../../styles/variables';
@import '../../../styles/mixins';


// sass-lint:disable no-vendor-prefixes
.ContractsExpiring {
  @include ie11-styles {
    -ms-grid-column: 5;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    display: block;
    margin: 7px;
    width: 26.5vw; }
  @include tablet-md {
    @include ie11-styles {
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      -ms-grid-row: 2;
      height: auto;
      width: 36vw; }
    grid-column: 1 / span 2;
    grid-row: 2;
    height: 100%;
    width: 100%; }
  @include tablet-sm {
    @include ie11-styles {
      width: 37vw; }
    width: 100%; }
  @include tablet-xs {
    @include ie11-styles {
      padding: 10px 0; }
    margin: 15px 0; }

  align-items: center;
  background-color: $white;
  box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-20-percent, 0 3px 4px 0 $colorBlack-20-percent;
  display: flex;
  flex-flow: column;
  grid-column: 5 / span 2;
  grid-row: 1;
  justify-content: space-between;

  &__expand {
    @include ie11-styles {
      -ms-grid-column: 4;
      -ms-grid-column-span: 2;
      width: 25vw; }
    @include tablet-md {
      @include ie11-styles {
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        height: auto;
        width: 36vw; }
      grid-column: 1 / span 2;
      grid-row: 2; }
    grid-column: 7 / span 2; }

  &__content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%; }

  &__metric-container {
    @include tablet-md {
      margin-bottom: 1em;
      padding: 0 1em; }
    @include tablet-xs {
      justify-content: center; }
    @include mobile-sm {
      flex-flow: row nowrap;
      margin-bottom: 2em; }
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    padding: 1vw; }

  &__circle {
    @include tablet-md {
      height: 118px;
      width: 118px; }
    border: 2px solid $colorChartColor3;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    height: 10vw;
    justify-content: center;
    width: 10vw; }

  &__metric {
    @include tablet-md {
      font-size: 14px;
      line-height: 1.71; }
    font-size: 20px;
    line-height: 1.5;
    text-align: center;

    span {
      @include tablet-md {
        font-size: 14px; }
      display: block;
      font-size: 16px; } }

  &__icon {
    align-self: center;
    height: 35px;
    margin-bottom: 8px;
    width: 33px; }

  &__text {
    @include tablet-md {
      font-size: 12px;
      letter-spacing: .48px; }
    flex: 1;
    font-size: 14px;
    line-height: 1.5;
    margin: 12px;
    text-align: left; }

  &__button {
    @include tablet-md {
      max-width: 240px; }
    @include mobile-sm {
      max-width: 100%; }
    align-self: center;
    margin-bottom: .85em; } }
