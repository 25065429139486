@import '../../../styles/variables';

.PopupHeader {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  &__container {
    margin: 0 13px 13px; }

  &__close-icon {
    align-self: flex-end;
    cursor: pointer;
    height: 11px;
    margin: 10px 10px 0;
    width: 11px; }

  &__title {
    font-family: $fontBold;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 0; }

  &__subtitle {
    color: $colorGray1;
    font-size: 10px;
    line-height: 1.5; } }
