@import '../../../styles/variables';
@import '../../../styles/mixins';


.ContactForm {
  padding-top: 1.5rem;

  &__error-msg {
    color: $colorSecondary6; }

  &--input {
    border: solid 1px $colorGray2;
    border-radius: 4px; }

  &--input-error {
    border: solid 2px $colorSecondary6;
    border-radius: 4px; }

  &__message {
    margin-bottom: 1rem;
    margin-top: 1rem; }

  &__message--input {
    min-height: 144px;
    padding: .5rem;
    width: 100%; }

  &__contact-info--container {
    @include mobile-sm {
      display: block; }
    display: grid;
    grid-column-gap: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem; }

  &__contact-info--name {
    grid-column: 1; }

  &__contact-info--email {
    grid-column: 2; }

  &__contact-info--input {
    padding: .5rem;
    width: 100%; }

  &__contact-info--label {
    display: block; }

  &__usage {
    padding-top: 1rem;

    h3 {
      padding-bottom: 1rem; } }

  &__checkbox--input {
    display: none; }

  &__checkbox-icon--checked {
    color: $colorSecondary3;
    stroke: $colorSecondary3; }

  &__checkbox-icon--unchecked {
    color: $white;
    stroke: $black;
    stroke-width: 14px; }

  &__checkbox--label {
    padding-left: .5rem; }

  &__other-reason {
    @include mobile-sm {
      width: calc(100% - 1.5rem); }
    border: solid 1px $colorGray2;
    border-radius: 4px;
    margin-left: 1.5rem;
    padding: .5rem;
    width: 50%; }

  &__submit {
    background-color: $colorSecondary3;
    border: 0;
    border-radius: 4px;
    color: $white;
    font-family: $fontBold;
    margin-top: 1rem;
    padding: .75rem 1rem;

    &:hover {
      box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-12-percent, 0 3px 4px 0 $colorBlack-14-percent; } }

  &__input-error {
    border: solid 1px $colorSecondary6; } }
