@import '../../../styles/mixins';
@import '../../../styles/variables';


.Toggle__checkbox {
  height: 0;
  visibility: hidden;
  width: 0;

  &:checked + .Toggle__label {
    .Toggle__button {
      left: calc(100% - 2px);
      transform: translateX(-100%); } } }

.Toggle__label {
  align-items: center;
  background: $colorGray3;
  border-radius: 12.5px;
  cursor: pointer;
  display: flex;
  height: 26px;
  justify-content: space-between;
  position: relative;
  transition: background-color .2s;
  width: 48px;

  &:active {
    .Toggle__button {
      width: 30px; } }

  &--on {
    background-color: $colorSecondary3; }

  .Toggle__button {
    background: $white;
    border-radius: 45px;
    box-shadow: 0 0 2px 0 $colorBlack-38-percent;
    content: '';
    height: 22px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: .2s;
    width: 22px; } }
