// SASS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home
//   width: $icon-home-width
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites
$sos-2019-a-k-1-x-name: 'sos-2019-AK_1X';
$sos-2019-a-k-1-x-x: 1050px;
$sos-2019-a-k-1-x-y: 150px;
$sos-2019-a-k-1-x-offset-x: -1050px;
$sos-2019-a-k-1-x-offset-y: -150px;
$sos-2019-a-k-1-x-width: 70px;
$sos-2019-a-k-1-x-height: 70px;
$sos-2019-a-k-1-x-total-width: 1269px;
$sos-2019-a-k-1-x-total-height: 1200px;
$sos-2019-a-k-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-a-k-1-x: (1050px, 150px, -1050px, -150px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-AK_1X', );
$sos-2019-a-k-2-x-name: 'sos-2019-AK_2X';
$sos-2019-a-k-2-x-x: 0;
$sos-2019-a-k-2-x-y: 0;
$sos-2019-a-k-2-x-offset-x: 0;
$sos-2019-a-k-2-x-offset-y: 0;
$sos-2019-a-k-2-x-width: 140px;
$sos-2019-a-k-2-x-height: 140px;
$sos-2019-a-k-2-x-total-width: 1269px;
$sos-2019-a-k-2-x-total-height: 1200px;
$sos-2019-a-k-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-a-k-2-x: (0, 0, 0, 0, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-AK_2X', );
$sos-2019-a-l-1-x-name: 'sos-2019-AL_1X';
$sos-2019-a-l-1-x-x: 1050px;
$sos-2019-a-l-1-x-y: 230px;
$sos-2019-a-l-1-x-offset-x: -1050px;
$sos-2019-a-l-1-x-offset-y: -230px;
$sos-2019-a-l-1-x-width: 70px;
$sos-2019-a-l-1-x-height: 70px;
$sos-2019-a-l-1-x-total-width: 1269px;
$sos-2019-a-l-1-x-total-height: 1200px;
$sos-2019-a-l-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-a-l-1-x: (1050px, 230px, -1050px, -230px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-AL_1X', );
$sos-2019-a-l-2-x-name: 'sos-2019-AL_2X';
$sos-2019-a-l-2-x-x: 150px;
$sos-2019-a-l-2-x-y: 0;
$sos-2019-a-l-2-x-offset-x: -150px;
$sos-2019-a-l-2-x-offset-y: 0;
$sos-2019-a-l-2-x-width: 140px;
$sos-2019-a-l-2-x-height: 140px;
$sos-2019-a-l-2-x-total-width: 1269px;
$sos-2019-a-l-2-x-total-height: 1200px;
$sos-2019-a-l-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-a-l-2-x: (150px, 0, -150px, 0, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-AL_2X', );
$sos-2019-a-r-1-x-name: 'sos-2019-AR_1X';
$sos-2019-a-r-1-x-x: 1050px;
$sos-2019-a-r-1-x-y: 310px;
$sos-2019-a-r-1-x-offset-x: -1050px;
$sos-2019-a-r-1-x-offset-y: -310px;
$sos-2019-a-r-1-x-width: 70px;
$sos-2019-a-r-1-x-height: 70px;
$sos-2019-a-r-1-x-total-width: 1269px;
$sos-2019-a-r-1-x-total-height: 1200px;
$sos-2019-a-r-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-a-r-1-x: (1050px, 310px, -1050px, -310px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-AR_1X', );
$sos-2019-a-r-2-x-name: 'sos-2019-AR_2X';
$sos-2019-a-r-2-x-x: 0;
$sos-2019-a-r-2-x-y: 150px;
$sos-2019-a-r-2-x-offset-x: 0;
$sos-2019-a-r-2-x-offset-y: -150px;
$sos-2019-a-r-2-x-width: 140px;
$sos-2019-a-r-2-x-height: 140px;
$sos-2019-a-r-2-x-total-width: 1269px;
$sos-2019-a-r-2-x-total-height: 1200px;
$sos-2019-a-r-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-a-r-2-x: (0, 150px, 0, -150px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-AR_2X', );
$sos-2019-a-z-1-x-name: 'sos-2019-AZ_1X';
$sos-2019-a-z-1-x-x: 1050px;
$sos-2019-a-z-1-x-y: 390px;
$sos-2019-a-z-1-x-offset-x: -1050px;
$sos-2019-a-z-1-x-offset-y: -390px;
$sos-2019-a-z-1-x-width: 70px;
$sos-2019-a-z-1-x-height: 70px;
$sos-2019-a-z-1-x-total-width: 1269px;
$sos-2019-a-z-1-x-total-height: 1200px;
$sos-2019-a-z-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-a-z-1-x: (1050px, 390px, -1050px, -390px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-AZ_1X', );
$sos-2019-a-z-2-x-name: 'sos-2019-AZ_2X';
$sos-2019-a-z-2-x-x: 150px;
$sos-2019-a-z-2-x-y: 150px;
$sos-2019-a-z-2-x-offset-x: -150px;
$sos-2019-a-z-2-x-offset-y: -150px;
$sos-2019-a-z-2-x-width: 140px;
$sos-2019-a-z-2-x-height: 140px;
$sos-2019-a-z-2-x-total-width: 1269px;
$sos-2019-a-z-2-x-total-height: 1200px;
$sos-2019-a-z-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-a-z-2-x: (150px, 150px, -150px, -150px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-AZ_2X', );
$sos-2019-c-a-1-x-name: 'sos-2019-CA_1X';
$sos-2019-c-a-1-x-x: 1050px;
$sos-2019-c-a-1-x-y: 470px;
$sos-2019-c-a-1-x-offset-x: -1050px;
$sos-2019-c-a-1-x-offset-y: -470px;
$sos-2019-c-a-1-x-width: 70px;
$sos-2019-c-a-1-x-height: 70px;
$sos-2019-c-a-1-x-total-width: 1269px;
$sos-2019-c-a-1-x-total-height: 1200px;
$sos-2019-c-a-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-c-a-1-x: (1050px, 470px, -1050px, -470px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-CA_1X', );
$sos-2019-c-a-2-x-name: 'sos-2019-CA_2X';
$sos-2019-c-a-2-x-x: 300px;
$sos-2019-c-a-2-x-y: 0;
$sos-2019-c-a-2-x-offset-x: -300px;
$sos-2019-c-a-2-x-offset-y: 0;
$sos-2019-c-a-2-x-width: 140px;
$sos-2019-c-a-2-x-height: 140px;
$sos-2019-c-a-2-x-total-width: 1269px;
$sos-2019-c-a-2-x-total-height: 1200px;
$sos-2019-c-a-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-c-a-2-x: (300px, 0, -300px, 0, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-CA_2X', );
$sos-2019-c-o-1-x-name: 'sos-2019-CO_1X';
$sos-2019-c-o-1-x-x: 1050px;
$sos-2019-c-o-1-x-y: 550px;
$sos-2019-c-o-1-x-offset-x: -1050px;
$sos-2019-c-o-1-x-offset-y: -550px;
$sos-2019-c-o-1-x-width: 70px;
$sos-2019-c-o-1-x-height: 70px;
$sos-2019-c-o-1-x-total-width: 1269px;
$sos-2019-c-o-1-x-total-height: 1200px;
$sos-2019-c-o-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-c-o-1-x: (1050px, 550px, -1050px, -550px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-CO_1X', );
$sos-2019-c-o-2-x-name: 'sos-2019-CO_2X';
$sos-2019-c-o-2-x-x: 300px;
$sos-2019-c-o-2-x-y: 150px;
$sos-2019-c-o-2-x-offset-x: -300px;
$sos-2019-c-o-2-x-offset-y: -150px;
$sos-2019-c-o-2-x-width: 140px;
$sos-2019-c-o-2-x-height: 140px;
$sos-2019-c-o-2-x-total-width: 1269px;
$sos-2019-c-o-2-x-total-height: 1200px;
$sos-2019-c-o-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-c-o-2-x: (300px, 150px, -300px, -150px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-CO_2X', );
$sos-2019-c-t-1-x-name: 'sos-2019-CT_1X';
$sos-2019-c-t-1-x-x: 1050px;
$sos-2019-c-t-1-x-y: 630px;
$sos-2019-c-t-1-x-offset-x: -1050px;
$sos-2019-c-t-1-x-offset-y: -630px;
$sos-2019-c-t-1-x-width: 70px;
$sos-2019-c-t-1-x-height: 70px;
$sos-2019-c-t-1-x-total-width: 1269px;
$sos-2019-c-t-1-x-total-height: 1200px;
$sos-2019-c-t-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-c-t-1-x: (1050px, 630px, -1050px, -630px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-CT_1X', );
$sos-2019-c-t-2-x-name: 'sos-2019-CT_2X';
$sos-2019-c-t-2-x-x: 0;
$sos-2019-c-t-2-x-y: 300px;
$sos-2019-c-t-2-x-offset-x: 0;
$sos-2019-c-t-2-x-offset-y: -300px;
$sos-2019-c-t-2-x-width: 140px;
$sos-2019-c-t-2-x-height: 140px;
$sos-2019-c-t-2-x-total-width: 1269px;
$sos-2019-c-t-2-x-total-height: 1200px;
$sos-2019-c-t-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-c-t-2-x: (0, 300px, 0, -300px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-CT_2X', );
$sos-2019-d-e-1-x-name: 'sos-2019-DE_1X';
$sos-2019-d-e-1-x-x: 1050px;
$sos-2019-d-e-1-x-y: 710px;
$sos-2019-d-e-1-x-offset-x: -1050px;
$sos-2019-d-e-1-x-offset-y: -710px;
$sos-2019-d-e-1-x-width: 70px;
$sos-2019-d-e-1-x-height: 70px;
$sos-2019-d-e-1-x-total-width: 1269px;
$sos-2019-d-e-1-x-total-height: 1200px;
$sos-2019-d-e-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-d-e-1-x: (1050px, 710px, -1050px, -710px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-DE_1X', );
$sos-2019-d-e-2-x-name: 'sos-2019-DE_2X';
$sos-2019-d-e-2-x-x: 150px;
$sos-2019-d-e-2-x-y: 300px;
$sos-2019-d-e-2-x-offset-x: -150px;
$sos-2019-d-e-2-x-offset-y: -300px;
$sos-2019-d-e-2-x-width: 140px;
$sos-2019-d-e-2-x-height: 140px;
$sos-2019-d-e-2-x-total-width: 1269px;
$sos-2019-d-e-2-x-total-height: 1200px;
$sos-2019-d-e-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-d-e-2-x: (150px, 300px, -150px, -300px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-DE_2X', );
$sos-2019-f-l-1-x-name: 'sos-2019-FL_1X';
$sos-2019-f-l-1-x-x: 1050px;
$sos-2019-f-l-1-x-y: 790px;
$sos-2019-f-l-1-x-offset-x: -1050px;
$sos-2019-f-l-1-x-offset-y: -790px;
$sos-2019-f-l-1-x-width: 70px;
$sos-2019-f-l-1-x-height: 70px;
$sos-2019-f-l-1-x-total-width: 1269px;
$sos-2019-f-l-1-x-total-height: 1200px;
$sos-2019-f-l-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-f-l-1-x: (1050px, 790px, -1050px, -790px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-FL_1X', );
$sos-2019-f-l-2-x-name: 'sos-2019-FL_2X';
$sos-2019-f-l-2-x-x: 300px;
$sos-2019-f-l-2-x-y: 300px;
$sos-2019-f-l-2-x-offset-x: -300px;
$sos-2019-f-l-2-x-offset-y: -300px;
$sos-2019-f-l-2-x-width: 140px;
$sos-2019-f-l-2-x-height: 140px;
$sos-2019-f-l-2-x-total-width: 1269px;
$sos-2019-f-l-2-x-total-height: 1200px;
$sos-2019-f-l-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-f-l-2-x: (300px, 300px, -300px, -300px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-FL_2X', );
$sos-2019-g-a-1-x-name: 'sos-2019-GA_1X';
$sos-2019-g-a-1-x-x: 1050px;
$sos-2019-g-a-1-x-y: 870px;
$sos-2019-g-a-1-x-offset-x: -1050px;
$sos-2019-g-a-1-x-offset-y: -870px;
$sos-2019-g-a-1-x-width: 70px;
$sos-2019-g-a-1-x-height: 70px;
$sos-2019-g-a-1-x-total-width: 1269px;
$sos-2019-g-a-1-x-total-height: 1200px;
$sos-2019-g-a-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-g-a-1-x: (1050px, 870px, -1050px, -870px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-GA_1X', );
$sos-2019-g-a-2-x-name: 'sos-2019-GA_2X';
$sos-2019-g-a-2-x-x: 450px;
$sos-2019-g-a-2-x-y: 0;
$sos-2019-g-a-2-x-offset-x: -450px;
$sos-2019-g-a-2-x-offset-y: 0;
$sos-2019-g-a-2-x-width: 140px;
$sos-2019-g-a-2-x-height: 140px;
$sos-2019-g-a-2-x-total-width: 1269px;
$sos-2019-g-a-2-x-total-height: 1200px;
$sos-2019-g-a-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-g-a-2-x: (450px, 0, -450px, 0, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-GA_2X', );
$sos-2019-h-i-1-x-name: 'sos-2019-HI_1X';
$sos-2019-h-i-1-x-x: 1050px;
$sos-2019-h-i-1-x-y: 950px;
$sos-2019-h-i-1-x-offset-x: -1050px;
$sos-2019-h-i-1-x-offset-y: -950px;
$sos-2019-h-i-1-x-width: 70px;
$sos-2019-h-i-1-x-height: 70px;
$sos-2019-h-i-1-x-total-width: 1269px;
$sos-2019-h-i-1-x-total-height: 1200px;
$sos-2019-h-i-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-h-i-1-x: (1050px, 950px, -1050px, -950px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-HI_1X', );
$sos-2019-h-i-2-x-name: 'sos-2019-HI_2X';
$sos-2019-h-i-2-x-x: 450px;
$sos-2019-h-i-2-x-y: 150px;
$sos-2019-h-i-2-x-offset-x: -450px;
$sos-2019-h-i-2-x-offset-y: -150px;
$sos-2019-h-i-2-x-width: 140px;
$sos-2019-h-i-2-x-height: 140px;
$sos-2019-h-i-2-x-total-width: 1269px;
$sos-2019-h-i-2-x-total-height: 1200px;
$sos-2019-h-i-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-h-i-2-x: (450px, 150px, -450px, -150px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-HI_2X', );
$sos-2019-i-a-1-x-name: 'sos-2019-IA_1X';
$sos-2019-i-a-1-x-x: 0;
$sos-2019-i-a-1-x-y: 1050px;
$sos-2019-i-a-1-x-offset-x: 0;
$sos-2019-i-a-1-x-offset-y: -1050px;
$sos-2019-i-a-1-x-width: 70px;
$sos-2019-i-a-1-x-height: 70px;
$sos-2019-i-a-1-x-total-width: 1269px;
$sos-2019-i-a-1-x-total-height: 1200px;
$sos-2019-i-a-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-i-a-1-x: (0, 1050px, 0, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-IA_1X', );
$sos-2019-i-a-2-x-name: 'sos-2019-IA_2X';
$sos-2019-i-a-2-x-x: 450px;
$sos-2019-i-a-2-x-y: 300px;
$sos-2019-i-a-2-x-offset-x: -450px;
$sos-2019-i-a-2-x-offset-y: -300px;
$sos-2019-i-a-2-x-width: 140px;
$sos-2019-i-a-2-x-height: 140px;
$sos-2019-i-a-2-x-total-width: 1269px;
$sos-2019-i-a-2-x-total-height: 1200px;
$sos-2019-i-a-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-i-a-2-x: (450px, 300px, -450px, -300px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-IA_2X', );
$sos-2019-i-d-1-x-name: 'sos-2019-ID_1X';
$sos-2019-i-d-1-x-x: 80px;
$sos-2019-i-d-1-x-y: 1050px;
$sos-2019-i-d-1-x-offset-x: -80px;
$sos-2019-i-d-1-x-offset-y: -1050px;
$sos-2019-i-d-1-x-width: 70px;
$sos-2019-i-d-1-x-height: 70px;
$sos-2019-i-d-1-x-total-width: 1269px;
$sos-2019-i-d-1-x-total-height: 1200px;
$sos-2019-i-d-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-i-d-1-x: (80px, 1050px, -80px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-ID_1X', );
$sos-2019-i-d-2-x-name: 'sos-2019-ID_2X';
$sos-2019-i-d-2-x-x: 0;
$sos-2019-i-d-2-x-y: 450px;
$sos-2019-i-d-2-x-offset-x: 0;
$sos-2019-i-d-2-x-offset-y: -450px;
$sos-2019-i-d-2-x-width: 140px;
$sos-2019-i-d-2-x-height: 140px;
$sos-2019-i-d-2-x-total-width: 1269px;
$sos-2019-i-d-2-x-total-height: 1200px;
$sos-2019-i-d-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-i-d-2-x: (0, 450px, 0, -450px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-ID_2X', );
$sos-2019-i-l-1-x-name: 'sos-2019-IL_1X';
$sos-2019-i-l-1-x-x: 160px;
$sos-2019-i-l-1-x-y: 1050px;
$sos-2019-i-l-1-x-offset-x: -160px;
$sos-2019-i-l-1-x-offset-y: -1050px;
$sos-2019-i-l-1-x-width: 70px;
$sos-2019-i-l-1-x-height: 70px;
$sos-2019-i-l-1-x-total-width: 1269px;
$sos-2019-i-l-1-x-total-height: 1200px;
$sos-2019-i-l-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-i-l-1-x: (160px, 1050px, -160px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-IL_1X', );
$sos-2019-i-l-2-x-name: 'sos-2019-IL_2X';
$sos-2019-i-l-2-x-x: 150px;
$sos-2019-i-l-2-x-y: 450px;
$sos-2019-i-l-2-x-offset-x: -150px;
$sos-2019-i-l-2-x-offset-y: -450px;
$sos-2019-i-l-2-x-width: 140px;
$sos-2019-i-l-2-x-height: 140px;
$sos-2019-i-l-2-x-total-width: 1269px;
$sos-2019-i-l-2-x-total-height: 1200px;
$sos-2019-i-l-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-i-l-2-x: (150px, 450px, -150px, -450px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-IL_2X', );
$sos-2019-i-n-1-x-name: 'sos-2019-IN_1X';
$sos-2019-i-n-1-x-x: 240px;
$sos-2019-i-n-1-x-y: 1050px;
$sos-2019-i-n-1-x-offset-x: -240px;
$sos-2019-i-n-1-x-offset-y: -1050px;
$sos-2019-i-n-1-x-width: 70px;
$sos-2019-i-n-1-x-height: 70px;
$sos-2019-i-n-1-x-total-width: 1269px;
$sos-2019-i-n-1-x-total-height: 1200px;
$sos-2019-i-n-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-i-n-1-x: (240px, 1050px, -240px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-IN_1X', );
$sos-2019-i-n-2-x-name: 'sos-2019-IN_2X';
$sos-2019-i-n-2-x-x: 300px;
$sos-2019-i-n-2-x-y: 450px;
$sos-2019-i-n-2-x-offset-x: -300px;
$sos-2019-i-n-2-x-offset-y: -450px;
$sos-2019-i-n-2-x-width: 140px;
$sos-2019-i-n-2-x-height: 140px;
$sos-2019-i-n-2-x-total-width: 1269px;
$sos-2019-i-n-2-x-total-height: 1200px;
$sos-2019-i-n-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-i-n-2-x: (300px, 450px, -300px, -450px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-IN_2X', );
$sos-2019-k-s-1-x-name: 'sos-2019-KS_1X';
$sos-2019-k-s-1-x-x: 320px;
$sos-2019-k-s-1-x-y: 1050px;
$sos-2019-k-s-1-x-offset-x: -320px;
$sos-2019-k-s-1-x-offset-y: -1050px;
$sos-2019-k-s-1-x-width: 70px;
$sos-2019-k-s-1-x-height: 70px;
$sos-2019-k-s-1-x-total-width: 1269px;
$sos-2019-k-s-1-x-total-height: 1200px;
$sos-2019-k-s-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-k-s-1-x: (320px, 1050px, -320px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-KS_1X', );
$sos-2019-k-s-2-x-name: 'sos-2019-KS_2X';
$sos-2019-k-s-2-x-x: 450px;
$sos-2019-k-s-2-x-y: 450px;
$sos-2019-k-s-2-x-offset-x: -450px;
$sos-2019-k-s-2-x-offset-y: -450px;
$sos-2019-k-s-2-x-width: 140px;
$sos-2019-k-s-2-x-height: 140px;
$sos-2019-k-s-2-x-total-width: 1269px;
$sos-2019-k-s-2-x-total-height: 1200px;
$sos-2019-k-s-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-k-s-2-x: (450px, 450px, -450px, -450px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-KS_2X', );
$sos-2019-k-y-1-x-name: 'sos-2019-KY_1X';
$sos-2019-k-y-1-x-x: 400px;
$sos-2019-k-y-1-x-y: 1050px;
$sos-2019-k-y-1-x-offset-x: -400px;
$sos-2019-k-y-1-x-offset-y: -1050px;
$sos-2019-k-y-1-x-width: 70px;
$sos-2019-k-y-1-x-height: 70px;
$sos-2019-k-y-1-x-total-width: 1269px;
$sos-2019-k-y-1-x-total-height: 1200px;
$sos-2019-k-y-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-k-y-1-x: (400px, 1050px, -400px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-KY_1X', );
$sos-2019-k-y-2-x-name: 'sos-2019-KY_2X';
$sos-2019-k-y-2-x-x: 600px;
$sos-2019-k-y-2-x-y: 0;
$sos-2019-k-y-2-x-offset-x: -600px;
$sos-2019-k-y-2-x-offset-y: 0;
$sos-2019-k-y-2-x-width: 140px;
$sos-2019-k-y-2-x-height: 140px;
$sos-2019-k-y-2-x-total-width: 1269px;
$sos-2019-k-y-2-x-total-height: 1200px;
$sos-2019-k-y-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-k-y-2-x: (600px, 0, -600px, 0, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-KY_2X', );
$sos-2019-l-a-1-x-name: 'sos-2019-LA_1X';
$sos-2019-l-a-1-x-x: 480px;
$sos-2019-l-a-1-x-y: 1050px;
$sos-2019-l-a-1-x-offset-x: -480px;
$sos-2019-l-a-1-x-offset-y: -1050px;
$sos-2019-l-a-1-x-width: 70px;
$sos-2019-l-a-1-x-height: 70px;
$sos-2019-l-a-1-x-total-width: 1269px;
$sos-2019-l-a-1-x-total-height: 1200px;
$sos-2019-l-a-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-l-a-1-x: (480px, 1050px, -480px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-LA_1X', );
$sos-2019-l-a-2-x-name: 'sos-2019-LA_2X';
$sos-2019-l-a-2-x-x: 600px;
$sos-2019-l-a-2-x-y: 150px;
$sos-2019-l-a-2-x-offset-x: -600px;
$sos-2019-l-a-2-x-offset-y: -150px;
$sos-2019-l-a-2-x-width: 140px;
$sos-2019-l-a-2-x-height: 140px;
$sos-2019-l-a-2-x-total-width: 1269px;
$sos-2019-l-a-2-x-total-height: 1200px;
$sos-2019-l-a-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-l-a-2-x: (600px, 150px, -600px, -150px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-LA_2X', );
$sos-2019-m-a-1-x-name: 'sos-2019-MA_1X';
$sos-2019-m-a-1-x-x: 560px;
$sos-2019-m-a-1-x-y: 1050px;
$sos-2019-m-a-1-x-offset-x: -560px;
$sos-2019-m-a-1-x-offset-y: -1050px;
$sos-2019-m-a-1-x-width: 70px;
$sos-2019-m-a-1-x-height: 70px;
$sos-2019-m-a-1-x-total-width: 1269px;
$sos-2019-m-a-1-x-total-height: 1200px;
$sos-2019-m-a-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-a-1-x: (560px, 1050px, -560px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MA_1X', );
$sos-2019-m-a-2-x-name: 'sos-2019-MA_2X';
$sos-2019-m-a-2-x-x: 600px;
$sos-2019-m-a-2-x-y: 300px;
$sos-2019-m-a-2-x-offset-x: -600px;
$sos-2019-m-a-2-x-offset-y: -300px;
$sos-2019-m-a-2-x-width: 140px;
$sos-2019-m-a-2-x-height: 140px;
$sos-2019-m-a-2-x-total-width: 1269px;
$sos-2019-m-a-2-x-total-height: 1200px;
$sos-2019-m-a-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-a-2-x: (600px, 300px, -600px, -300px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MA_2X', );
$sos-2019-m-d-1-x-name: 'sos-2019-MD_1X';
$sos-2019-m-d-1-x-x: 640px;
$sos-2019-m-d-1-x-y: 1050px;
$sos-2019-m-d-1-x-offset-x: -640px;
$sos-2019-m-d-1-x-offset-y: -1050px;
$sos-2019-m-d-1-x-width: 70px;
$sos-2019-m-d-1-x-height: 70px;
$sos-2019-m-d-1-x-total-width: 1269px;
$sos-2019-m-d-1-x-total-height: 1200px;
$sos-2019-m-d-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-d-1-x: (640px, 1050px, -640px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MD_1X', );
$sos-2019-m-d-2-x-name: 'sos-2019-MD_2X';
$sos-2019-m-d-2-x-x: 600px;
$sos-2019-m-d-2-x-y: 450px;
$sos-2019-m-d-2-x-offset-x: -600px;
$sos-2019-m-d-2-x-offset-y: -450px;
$sos-2019-m-d-2-x-width: 140px;
$sos-2019-m-d-2-x-height: 140px;
$sos-2019-m-d-2-x-total-width: 1269px;
$sos-2019-m-d-2-x-total-height: 1200px;
$sos-2019-m-d-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-d-2-x: (600px, 450px, -600px, -450px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MD_2X', );
$sos-2019-m-e-1-x-name: 'sos-2019-ME_1X';
$sos-2019-m-e-1-x-x: 720px;
$sos-2019-m-e-1-x-y: 1050px;
$sos-2019-m-e-1-x-offset-x: -720px;
$sos-2019-m-e-1-x-offset-y: -1050px;
$sos-2019-m-e-1-x-width: 70px;
$sos-2019-m-e-1-x-height: 70px;
$sos-2019-m-e-1-x-total-width: 1269px;
$sos-2019-m-e-1-x-total-height: 1200px;
$sos-2019-m-e-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-e-1-x: (720px, 1050px, -720px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-ME_1X', );
$sos-2019-m-e-2-x-name: 'sos-2019-ME_2X';
$sos-2019-m-e-2-x-x: 0;
$sos-2019-m-e-2-x-y: 600px;
$sos-2019-m-e-2-x-offset-x: 0;
$sos-2019-m-e-2-x-offset-y: -600px;
$sos-2019-m-e-2-x-width: 140px;
$sos-2019-m-e-2-x-height: 140px;
$sos-2019-m-e-2-x-total-width: 1269px;
$sos-2019-m-e-2-x-total-height: 1200px;
$sos-2019-m-e-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-e-2-x: (0, 600px, 0, -600px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-ME_2X', );
$sos-2019-m-i-1-x-name: 'sos-2019-MI_1X';
$sos-2019-m-i-1-x-x: 800px;
$sos-2019-m-i-1-x-y: 1050px;
$sos-2019-m-i-1-x-offset-x: -800px;
$sos-2019-m-i-1-x-offset-y: -1050px;
$sos-2019-m-i-1-x-width: 70px;
$sos-2019-m-i-1-x-height: 70px;
$sos-2019-m-i-1-x-total-width: 1269px;
$sos-2019-m-i-1-x-total-height: 1200px;
$sos-2019-m-i-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-i-1-x: (800px, 1050px, -800px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MI_1X', );
$sos-2019-m-i-2-x-name: 'sos-2019-MI_2X';
$sos-2019-m-i-2-x-x: 150px;
$sos-2019-m-i-2-x-y: 600px;
$sos-2019-m-i-2-x-offset-x: -150px;
$sos-2019-m-i-2-x-offset-y: -600px;
$sos-2019-m-i-2-x-width: 140px;
$sos-2019-m-i-2-x-height: 140px;
$sos-2019-m-i-2-x-total-width: 1269px;
$sos-2019-m-i-2-x-total-height: 1200px;
$sos-2019-m-i-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-i-2-x: (150px, 600px, -150px, -600px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MI_2X', );
$sos-2019-m-n-1-x-name: 'sos-2019-MN_1X';
$sos-2019-m-n-1-x-x: 880px;
$sos-2019-m-n-1-x-y: 1050px;
$sos-2019-m-n-1-x-offset-x: -880px;
$sos-2019-m-n-1-x-offset-y: -1050px;
$sos-2019-m-n-1-x-width: 70px;
$sos-2019-m-n-1-x-height: 70px;
$sos-2019-m-n-1-x-total-width: 1269px;
$sos-2019-m-n-1-x-total-height: 1200px;
$sos-2019-m-n-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-n-1-x: (880px, 1050px, -880px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MN_1X', );
$sos-2019-m-n-2-x-name: 'sos-2019-MN_2X';
$sos-2019-m-n-2-x-x: 300px;
$sos-2019-m-n-2-x-y: 600px;
$sos-2019-m-n-2-x-offset-x: -300px;
$sos-2019-m-n-2-x-offset-y: -600px;
$sos-2019-m-n-2-x-width: 140px;
$sos-2019-m-n-2-x-height: 140px;
$sos-2019-m-n-2-x-total-width: 1269px;
$sos-2019-m-n-2-x-total-height: 1200px;
$sos-2019-m-n-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-n-2-x: (300px, 600px, -300px, -600px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MN_2X', );
$sos-2019-m-o-1-x-name: 'sos-2019-MO_1X';
$sos-2019-m-o-1-x-x: 960px;
$sos-2019-m-o-1-x-y: 1050px;
$sos-2019-m-o-1-x-offset-x: -960px;
$sos-2019-m-o-1-x-offset-y: -1050px;
$sos-2019-m-o-1-x-width: 70px;
$sos-2019-m-o-1-x-height: 70px;
$sos-2019-m-o-1-x-total-width: 1269px;
$sos-2019-m-o-1-x-total-height: 1200px;
$sos-2019-m-o-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-o-1-x: (960px, 1050px, -960px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MO_1X', );
$sos-2019-m-o-2-x-name: 'sos-2019-MO_2X';
$sos-2019-m-o-2-x-x: 450px;
$sos-2019-m-o-2-x-y: 600px;
$sos-2019-m-o-2-x-offset-x: -450px;
$sos-2019-m-o-2-x-offset-y: -600px;
$sos-2019-m-o-2-x-width: 140px;
$sos-2019-m-o-2-x-height: 140px;
$sos-2019-m-o-2-x-total-width: 1269px;
$sos-2019-m-o-2-x-total-height: 1200px;
$sos-2019-m-o-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-o-2-x: (450px, 600px, -450px, -600px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MO_2X', );
$sos-2019-m-s-1-x-name: 'sos-2019-MS_1X';
$sos-2019-m-s-1-x-x: 1040px;
$sos-2019-m-s-1-x-y: 1050px;
$sos-2019-m-s-1-x-offset-x: -1040px;
$sos-2019-m-s-1-x-offset-y: -1050px;
$sos-2019-m-s-1-x-width: 70px;
$sos-2019-m-s-1-x-height: 70px;
$sos-2019-m-s-1-x-total-width: 1269px;
$sos-2019-m-s-1-x-total-height: 1200px;
$sos-2019-m-s-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-s-1-x: (1040px, 1050px, -1040px, -1050px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MS_1X', );
$sos-2019-m-s-2-x-name: 'sos-2019-MS_2X';
$sos-2019-m-s-2-x-x: 600px;
$sos-2019-m-s-2-x-y: 600px;
$sos-2019-m-s-2-x-offset-x: -600px;
$sos-2019-m-s-2-x-offset-y: -600px;
$sos-2019-m-s-2-x-width: 140px;
$sos-2019-m-s-2-x-height: 140px;
$sos-2019-m-s-2-x-total-width: 1269px;
$sos-2019-m-s-2-x-total-height: 1200px;
$sos-2019-m-s-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-s-2-x: (600px, 600px, -600px, -600px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MS_2X', );
$sos-2019-m-t-1-x-name: 'sos-2019-MT_1X';
$sos-2019-m-t-1-x-x: 1199px;
$sos-2019-m-t-1-x-y: 0;
$sos-2019-m-t-1-x-offset-x: -1199px;
$sos-2019-m-t-1-x-offset-y: 0;
$sos-2019-m-t-1-x-width: 70px;
$sos-2019-m-t-1-x-height: 70px;
$sos-2019-m-t-1-x-total-width: 1269px;
$sos-2019-m-t-1-x-total-height: 1200px;
$sos-2019-m-t-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-t-1-x: (1199px, 0, -1199px, 0, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MT_1X', );
$sos-2019-m-t-2-x-name: 'sos-2019-MT_2X';
$sos-2019-m-t-2-x-x: 750px;
$sos-2019-m-t-2-x-y: 0;
$sos-2019-m-t-2-x-offset-x: -750px;
$sos-2019-m-t-2-x-offset-y: 0;
$sos-2019-m-t-2-x-width: 140px;
$sos-2019-m-t-2-x-height: 140px;
$sos-2019-m-t-2-x-total-width: 1269px;
$sos-2019-m-t-2-x-total-height: 1200px;
$sos-2019-m-t-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-m-t-2-x: (750px, 0, -750px, 0, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-MT_2X', );
$sos-2019-n-c-1-x-name: 'sos-2019-NC_1X';
$sos-2019-n-c-1-x-x: 1199px;
$sos-2019-n-c-1-x-y: 80px;
$sos-2019-n-c-1-x-offset-x: -1199px;
$sos-2019-n-c-1-x-offset-y: -80px;
$sos-2019-n-c-1-x-width: 70px;
$sos-2019-n-c-1-x-height: 70px;
$sos-2019-n-c-1-x-total-width: 1269px;
$sos-2019-n-c-1-x-total-height: 1200px;
$sos-2019-n-c-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-c-1-x: (1199px, 80px, -1199px, -80px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NC_1X', );
$sos-2019-n-c-2-x-name: 'sos-2019-NC_2X';
$sos-2019-n-c-2-x-x: 750px;
$sos-2019-n-c-2-x-y: 150px;
$sos-2019-n-c-2-x-offset-x: -750px;
$sos-2019-n-c-2-x-offset-y: -150px;
$sos-2019-n-c-2-x-width: 140px;
$sos-2019-n-c-2-x-height: 140px;
$sos-2019-n-c-2-x-total-width: 1269px;
$sos-2019-n-c-2-x-total-height: 1200px;
$sos-2019-n-c-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-c-2-x: (750px, 150px, -750px, -150px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NC_2X', );
$sos-2019-n-d-1-x-name: 'sos-2019-ND_1X';
$sos-2019-n-d-1-x-x: 1199px;
$sos-2019-n-d-1-x-y: 160px;
$sos-2019-n-d-1-x-offset-x: -1199px;
$sos-2019-n-d-1-x-offset-y: -160px;
$sos-2019-n-d-1-x-width: 70px;
$sos-2019-n-d-1-x-height: 70px;
$sos-2019-n-d-1-x-total-width: 1269px;
$sos-2019-n-d-1-x-total-height: 1200px;
$sos-2019-n-d-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-d-1-x: (1199px, 160px, -1199px, -160px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-ND_1X', );
$sos-2019-n-d-2-x-name: 'sos-2019-ND_2X';
$sos-2019-n-d-2-x-x: 750px;
$sos-2019-n-d-2-x-y: 300px;
$sos-2019-n-d-2-x-offset-x: -750px;
$sos-2019-n-d-2-x-offset-y: -300px;
$sos-2019-n-d-2-x-width: 140px;
$sos-2019-n-d-2-x-height: 140px;
$sos-2019-n-d-2-x-total-width: 1269px;
$sos-2019-n-d-2-x-total-height: 1200px;
$sos-2019-n-d-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-d-2-x: (750px, 300px, -750px, -300px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-ND_2X', );
$sos-2019-n-e-1-x-name: 'sos-2019-NE_1X';
$sos-2019-n-e-1-x-x: 1199px;
$sos-2019-n-e-1-x-y: 240px;
$sos-2019-n-e-1-x-offset-x: -1199px;
$sos-2019-n-e-1-x-offset-y: -240px;
$sos-2019-n-e-1-x-width: 70px;
$sos-2019-n-e-1-x-height: 70px;
$sos-2019-n-e-1-x-total-width: 1269px;
$sos-2019-n-e-1-x-total-height: 1200px;
$sos-2019-n-e-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-e-1-x: (1199px, 240px, -1199px, -240px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NE_1X', );
$sos-2019-n-e-2-x-name: 'sos-2019-NE_2X';
$sos-2019-n-e-2-x-x: 750px;
$sos-2019-n-e-2-x-y: 450px;
$sos-2019-n-e-2-x-offset-x: -750px;
$sos-2019-n-e-2-x-offset-y: -450px;
$sos-2019-n-e-2-x-width: 140px;
$sos-2019-n-e-2-x-height: 140px;
$sos-2019-n-e-2-x-total-width: 1269px;
$sos-2019-n-e-2-x-total-height: 1200px;
$sos-2019-n-e-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-e-2-x: (750px, 450px, -750px, -450px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NE_2X', );
$sos-2019-n-h-1-x-name: 'sos-2019-NH_1X';
$sos-2019-n-h-1-x-x: 1199px;
$sos-2019-n-h-1-x-y: 320px;
$sos-2019-n-h-1-x-offset-x: -1199px;
$sos-2019-n-h-1-x-offset-y: -320px;
$sos-2019-n-h-1-x-width: 70px;
$sos-2019-n-h-1-x-height: 70px;
$sos-2019-n-h-1-x-total-width: 1269px;
$sos-2019-n-h-1-x-total-height: 1200px;
$sos-2019-n-h-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-h-1-x: (1199px, 320px, -1199px, -320px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NH_1X', );
$sos-2019-n-h-2-x-name: 'sos-2019-NH_2X';
$sos-2019-n-h-2-x-x: 750px;
$sos-2019-n-h-2-x-y: 600px;
$sos-2019-n-h-2-x-offset-x: -750px;
$sos-2019-n-h-2-x-offset-y: -600px;
$sos-2019-n-h-2-x-width: 140px;
$sos-2019-n-h-2-x-height: 140px;
$sos-2019-n-h-2-x-total-width: 1269px;
$sos-2019-n-h-2-x-total-height: 1200px;
$sos-2019-n-h-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-h-2-x: (750px, 600px, -750px, -600px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NH_2X', );
$sos-2019-n-j-1-x-name: 'sos-2019-NJ_1X';
$sos-2019-n-j-1-x-x: 1199px;
$sos-2019-n-j-1-x-y: 400px;
$sos-2019-n-j-1-x-offset-x: -1199px;
$sos-2019-n-j-1-x-offset-y: -400px;
$sos-2019-n-j-1-x-width: 70px;
$sos-2019-n-j-1-x-height: 70px;
$sos-2019-n-j-1-x-total-width: 1269px;
$sos-2019-n-j-1-x-total-height: 1200px;
$sos-2019-n-j-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-j-1-x: (1199px, 400px, -1199px, -400px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NJ_1X', );
$sos-2019-n-j-2-x-name: 'sos-2019-NJ_2X';
$sos-2019-n-j-2-x-x: 0;
$sos-2019-n-j-2-x-y: 750px;
$sos-2019-n-j-2-x-offset-x: 0;
$sos-2019-n-j-2-x-offset-y: -750px;
$sos-2019-n-j-2-x-width: 140px;
$sos-2019-n-j-2-x-height: 140px;
$sos-2019-n-j-2-x-total-width: 1269px;
$sos-2019-n-j-2-x-total-height: 1200px;
$sos-2019-n-j-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-j-2-x: (0, 750px, 0, -750px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NJ_2X', );
$sos-2019-n-m-1-x-name: 'sos-2019-NM_1X';
$sos-2019-n-m-1-x-x: 1199px;
$sos-2019-n-m-1-x-y: 480px;
$sos-2019-n-m-1-x-offset-x: -1199px;
$sos-2019-n-m-1-x-offset-y: -480px;
$sos-2019-n-m-1-x-width: 70px;
$sos-2019-n-m-1-x-height: 70px;
$sos-2019-n-m-1-x-total-width: 1269px;
$sos-2019-n-m-1-x-total-height: 1200px;
$sos-2019-n-m-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-m-1-x: (1199px, 480px, -1199px, -480px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NM_1X', );
$sos-2019-n-m-2-x-name: 'sos-2019-NM_2X';
$sos-2019-n-m-2-x-x: 150px;
$sos-2019-n-m-2-x-y: 750px;
$sos-2019-n-m-2-x-offset-x: -150px;
$sos-2019-n-m-2-x-offset-y: -750px;
$sos-2019-n-m-2-x-width: 140px;
$sos-2019-n-m-2-x-height: 140px;
$sos-2019-n-m-2-x-total-width: 1269px;
$sos-2019-n-m-2-x-total-height: 1200px;
$sos-2019-n-m-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-m-2-x: (150px, 750px, -150px, -750px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NM_2X', );
$sos-2019-n-v-1-x-name: 'sos-2019-NV_1X';
$sos-2019-n-v-1-x-x: 1199px;
$sos-2019-n-v-1-x-y: 560px;
$sos-2019-n-v-1-x-offset-x: -1199px;
$sos-2019-n-v-1-x-offset-y: -560px;
$sos-2019-n-v-1-x-width: 70px;
$sos-2019-n-v-1-x-height: 70px;
$sos-2019-n-v-1-x-total-width: 1269px;
$sos-2019-n-v-1-x-total-height: 1200px;
$sos-2019-n-v-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-v-1-x: (1199px, 560px, -1199px, -560px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NV_1X', );
$sos-2019-n-v-2-x-name: 'sos-2019-NV_2X';
$sos-2019-n-v-2-x-x: 300px;
$sos-2019-n-v-2-x-y: 750px;
$sos-2019-n-v-2-x-offset-x: -300px;
$sos-2019-n-v-2-x-offset-y: -750px;
$sos-2019-n-v-2-x-width: 140px;
$sos-2019-n-v-2-x-height: 140px;
$sos-2019-n-v-2-x-total-width: 1269px;
$sos-2019-n-v-2-x-total-height: 1200px;
$sos-2019-n-v-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-v-2-x: (300px, 750px, -300px, -750px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NV_2X', );
$sos-2019-n-y-1-x-name: 'sos-2019-NY_1X';
$sos-2019-n-y-1-x-x: 1199px;
$sos-2019-n-y-1-x-y: 640px;
$sos-2019-n-y-1-x-offset-x: -1199px;
$sos-2019-n-y-1-x-offset-y: -640px;
$sos-2019-n-y-1-x-width: 70px;
$sos-2019-n-y-1-x-height: 70px;
$sos-2019-n-y-1-x-total-width: 1269px;
$sos-2019-n-y-1-x-total-height: 1200px;
$sos-2019-n-y-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-y-1-x: (1199px, 640px, -1199px, -640px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NY_1X', );
$sos-2019-n-y-2-x-name: 'sos-2019-NY_2X';
$sos-2019-n-y-2-x-x: 450px;
$sos-2019-n-y-2-x-y: 750px;
$sos-2019-n-y-2-x-offset-x: -450px;
$sos-2019-n-y-2-x-offset-y: -750px;
$sos-2019-n-y-2-x-width: 140px;
$sos-2019-n-y-2-x-height: 140px;
$sos-2019-n-y-2-x-total-width: 1269px;
$sos-2019-n-y-2-x-total-height: 1200px;
$sos-2019-n-y-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-n-y-2-x: (450px, 750px, -450px, -750px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-NY_2X', );
$sos-2019-o-h-1-x-name: 'sos-2019-OH_1X';
$sos-2019-o-h-1-x-x: 1199px;
$sos-2019-o-h-1-x-y: 720px;
$sos-2019-o-h-1-x-offset-x: -1199px;
$sos-2019-o-h-1-x-offset-y: -720px;
$sos-2019-o-h-1-x-width: 70px;
$sos-2019-o-h-1-x-height: 70px;
$sos-2019-o-h-1-x-total-width: 1269px;
$sos-2019-o-h-1-x-total-height: 1200px;
$sos-2019-o-h-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-o-h-1-x: (1199px, 720px, -1199px, -720px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-OH_1X', );
$sos-2019-o-h-2-x-name: 'sos-2019-OH_2X';
$sos-2019-o-h-2-x-x: 600px;
$sos-2019-o-h-2-x-y: 750px;
$sos-2019-o-h-2-x-offset-x: -600px;
$sos-2019-o-h-2-x-offset-y: -750px;
$sos-2019-o-h-2-x-width: 140px;
$sos-2019-o-h-2-x-height: 140px;
$sos-2019-o-h-2-x-total-width: 1269px;
$sos-2019-o-h-2-x-total-height: 1200px;
$sos-2019-o-h-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-o-h-2-x: (600px, 750px, -600px, -750px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-OH_2X', );
$sos-2019-o-k-1-x-name: 'sos-2019-OK_1X';
$sos-2019-o-k-1-x-x: 1199px;
$sos-2019-o-k-1-x-y: 800px;
$sos-2019-o-k-1-x-offset-x: -1199px;
$sos-2019-o-k-1-x-offset-y: -800px;
$sos-2019-o-k-1-x-width: 70px;
$sos-2019-o-k-1-x-height: 70px;
$sos-2019-o-k-1-x-total-width: 1269px;
$sos-2019-o-k-1-x-total-height: 1200px;
$sos-2019-o-k-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-o-k-1-x: (1199px, 800px, -1199px, -800px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-OK_1X', );
$sos-2019-o-k-2-x-name: 'sos-2019-OK_2X';
$sos-2019-o-k-2-x-x: 750px;
$sos-2019-o-k-2-x-y: 750px;
$sos-2019-o-k-2-x-offset-x: -750px;
$sos-2019-o-k-2-x-offset-y: -750px;
$sos-2019-o-k-2-x-width: 140px;
$sos-2019-o-k-2-x-height: 140px;
$sos-2019-o-k-2-x-total-width: 1269px;
$sos-2019-o-k-2-x-total-height: 1200px;
$sos-2019-o-k-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-o-k-2-x: (750px, 750px, -750px, -750px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-OK_2X', );
$sos-2019-o-r-1-x-name: 'sos-2019-OR_1X';
$sos-2019-o-r-1-x-x: 1199px;
$sos-2019-o-r-1-x-y: 880px;
$sos-2019-o-r-1-x-offset-x: -1199px;
$sos-2019-o-r-1-x-offset-y: -880px;
$sos-2019-o-r-1-x-width: 70px;
$sos-2019-o-r-1-x-height: 70px;
$sos-2019-o-r-1-x-total-width: 1269px;
$sos-2019-o-r-1-x-total-height: 1200px;
$sos-2019-o-r-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-o-r-1-x: (1199px, 880px, -1199px, -880px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-OR_1X', );
$sos-2019-o-r-2-x-name: 'sos-2019-OR_2X';
$sos-2019-o-r-2-x-x: 900px;
$sos-2019-o-r-2-x-y: 0;
$sos-2019-o-r-2-x-offset-x: -900px;
$sos-2019-o-r-2-x-offset-y: 0;
$sos-2019-o-r-2-x-width: 140px;
$sos-2019-o-r-2-x-height: 140px;
$sos-2019-o-r-2-x-total-width: 1269px;
$sos-2019-o-r-2-x-total-height: 1200px;
$sos-2019-o-r-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-o-r-2-x: (900px, 0, -900px, 0, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-OR_2X', );
$sos-2019-p-a-1-x-name: 'sos-2019-PA_1X';
$sos-2019-p-a-1-x-x: 1199px;
$sos-2019-p-a-1-x-y: 960px;
$sos-2019-p-a-1-x-offset-x: -1199px;
$sos-2019-p-a-1-x-offset-y: -960px;
$sos-2019-p-a-1-x-width: 70px;
$sos-2019-p-a-1-x-height: 70px;
$sos-2019-p-a-1-x-total-width: 1269px;
$sos-2019-p-a-1-x-total-height: 1200px;
$sos-2019-p-a-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-p-a-1-x: (1199px, 960px, -1199px, -960px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-PA_1X', );
$sos-2019-p-a-2-x-name: 'sos-2019-PA_2X';
$sos-2019-p-a-2-x-x: 900px;
$sos-2019-p-a-2-x-y: 150px;
$sos-2019-p-a-2-x-offset-x: -900px;
$sos-2019-p-a-2-x-offset-y: -150px;
$sos-2019-p-a-2-x-width: 140px;
$sos-2019-p-a-2-x-height: 140px;
$sos-2019-p-a-2-x-total-width: 1269px;
$sos-2019-p-a-2-x-total-height: 1200px;
$sos-2019-p-a-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-p-a-2-x: (900px, 150px, -900px, -150px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-PA_2X', );
$sos-2019-r-i-1-x-name: 'sos-2019-RI_1X';
$sos-2019-r-i-1-x-x: 1199px;
$sos-2019-r-i-1-x-y: 1040px;
$sos-2019-r-i-1-x-offset-x: -1199px;
$sos-2019-r-i-1-x-offset-y: -1040px;
$sos-2019-r-i-1-x-width: 70px;
$sos-2019-r-i-1-x-height: 70px;
$sos-2019-r-i-1-x-total-width: 1269px;
$sos-2019-r-i-1-x-total-height: 1200px;
$sos-2019-r-i-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-r-i-1-x: (1199px, 1040px, -1199px, -1040px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-RI_1X', );
$sos-2019-r-i-2-x-name: 'sos-2019-RI_2X';
$sos-2019-r-i-2-x-x: 900px;
$sos-2019-r-i-2-x-y: 300px;
$sos-2019-r-i-2-x-offset-x: -900px;
$sos-2019-r-i-2-x-offset-y: -300px;
$sos-2019-r-i-2-x-width: 140px;
$sos-2019-r-i-2-x-height: 140px;
$sos-2019-r-i-2-x-total-width: 1269px;
$sos-2019-r-i-2-x-total-height: 1200px;
$sos-2019-r-i-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-r-i-2-x: (900px, 300px, -900px, -300px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-RI_2X', );
$sos-2019-s-c-1-x-name: 'sos-2019-SC_1X';
$sos-2019-s-c-1-x-x: 0;
$sos-2019-s-c-1-x-y: 1130px;
$sos-2019-s-c-1-x-offset-x: 0;
$sos-2019-s-c-1-x-offset-y: -1130px;
$sos-2019-s-c-1-x-width: 70px;
$sos-2019-s-c-1-x-height: 70px;
$sos-2019-s-c-1-x-total-width: 1269px;
$sos-2019-s-c-1-x-total-height: 1200px;
$sos-2019-s-c-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-s-c-1-x: (0, 1130px, 0, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-SC_1X', );
$sos-2019-s-c-2-x-name: 'sos-2019-SC_2X';
$sos-2019-s-c-2-x-x: 1050px;
$sos-2019-s-c-2-x-y: 0;
$sos-2019-s-c-2-x-offset-x: -1050px;
$sos-2019-s-c-2-x-offset-y: 0;
$sos-2019-s-c-2-x-width: 139px;
$sos-2019-s-c-2-x-height: 140px;
$sos-2019-s-c-2-x-total-width: 1269px;
$sos-2019-s-c-2-x-total-height: 1200px;
$sos-2019-s-c-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-s-c-2-x: (1050px, 0, -1050px, 0, 139px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-SC_2X', );
$sos-2019-s-d-1-x-name: 'sos-2019-SD_1X';
$sos-2019-s-d-1-x-x: 80px;
$sos-2019-s-d-1-x-y: 1130px;
$sos-2019-s-d-1-x-offset-x: -80px;
$sos-2019-s-d-1-x-offset-y: -1130px;
$sos-2019-s-d-1-x-width: 70px;
$sos-2019-s-d-1-x-height: 70px;
$sos-2019-s-d-1-x-total-width: 1269px;
$sos-2019-s-d-1-x-total-height: 1200px;
$sos-2019-s-d-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-s-d-1-x: (80px, 1130px, -80px, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-SD_1X', );
$sos-2019-s-d-2-x-name: 'sos-2019-SD_2X';
$sos-2019-s-d-2-x-x: 900px;
$sos-2019-s-d-2-x-y: 450px;
$sos-2019-s-d-2-x-offset-x: -900px;
$sos-2019-s-d-2-x-offset-y: -450px;
$sos-2019-s-d-2-x-width: 140px;
$sos-2019-s-d-2-x-height: 140px;
$sos-2019-s-d-2-x-total-width: 1269px;
$sos-2019-s-d-2-x-total-height: 1200px;
$sos-2019-s-d-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-s-d-2-x: (900px, 450px, -900px, -450px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-SD_2X', );
$sos-2019-t-n-1-x-name: 'sos-2019-TN_1X';
$sos-2019-t-n-1-x-x: 160px;
$sos-2019-t-n-1-x-y: 1130px;
$sos-2019-t-n-1-x-offset-x: -160px;
$sos-2019-t-n-1-x-offset-y: -1130px;
$sos-2019-t-n-1-x-width: 70px;
$sos-2019-t-n-1-x-height: 70px;
$sos-2019-t-n-1-x-total-width: 1269px;
$sos-2019-t-n-1-x-total-height: 1200px;
$sos-2019-t-n-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-t-n-1-x: (160px, 1130px, -160px, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-TN_1X', );
$sos-2019-t-n-2-x-name: 'sos-2019-TN_2X';
$sos-2019-t-n-2-x-x: 900px;
$sos-2019-t-n-2-x-y: 600px;
$sos-2019-t-n-2-x-offset-x: -900px;
$sos-2019-t-n-2-x-offset-y: -600px;
$sos-2019-t-n-2-x-width: 140px;
$sos-2019-t-n-2-x-height: 140px;
$sos-2019-t-n-2-x-total-width: 1269px;
$sos-2019-t-n-2-x-total-height: 1200px;
$sos-2019-t-n-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-t-n-2-x: (900px, 600px, -900px, -600px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-TN_2X', );
$sos-2019-t-x-1-x-name: 'sos-2019-TX_1X';
$sos-2019-t-x-1-x-x: 240px;
$sos-2019-t-x-1-x-y: 1130px;
$sos-2019-t-x-1-x-offset-x: -240px;
$sos-2019-t-x-1-x-offset-y: -1130px;
$sos-2019-t-x-1-x-width: 70px;
$sos-2019-t-x-1-x-height: 70px;
$sos-2019-t-x-1-x-total-width: 1269px;
$sos-2019-t-x-1-x-total-height: 1200px;
$sos-2019-t-x-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-t-x-1-x: (240px, 1130px, -240px, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-TX_1X', );
$sos-2019-t-x-2-x-name: 'sos-2019-TX_2X';
$sos-2019-t-x-2-x-x: 900px;
$sos-2019-t-x-2-x-y: 750px;
$sos-2019-t-x-2-x-offset-x: -900px;
$sos-2019-t-x-2-x-offset-y: -750px;
$sos-2019-t-x-2-x-width: 140px;
$sos-2019-t-x-2-x-height: 140px;
$sos-2019-t-x-2-x-total-width: 1269px;
$sos-2019-t-x-2-x-total-height: 1200px;
$sos-2019-t-x-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-t-x-2-x: (900px, 750px, -900px, -750px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-TX_2X', );
$sos-2019-u-t-1-x-name: 'sos-2019-UT_1X';
$sos-2019-u-t-1-x-x: 320px;
$sos-2019-u-t-1-x-y: 1130px;
$sos-2019-u-t-1-x-offset-x: -320px;
$sos-2019-u-t-1-x-offset-y: -1130px;
$sos-2019-u-t-1-x-width: 70px;
$sos-2019-u-t-1-x-height: 70px;
$sos-2019-u-t-1-x-total-width: 1269px;
$sos-2019-u-t-1-x-total-height: 1200px;
$sos-2019-u-t-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-u-t-1-x: (320px, 1130px, -320px, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-UT_1X', );
$sos-2019-u-t-2-x-name: 'sos-2019-UT_2X';
$sos-2019-u-t-2-x-x: 0;
$sos-2019-u-t-2-x-y: 900px;
$sos-2019-u-t-2-x-offset-x: 0;
$sos-2019-u-t-2-x-offset-y: -900px;
$sos-2019-u-t-2-x-width: 140px;
$sos-2019-u-t-2-x-height: 140px;
$sos-2019-u-t-2-x-total-width: 1269px;
$sos-2019-u-t-2-x-total-height: 1200px;
$sos-2019-u-t-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-u-t-2-x: (0, 900px, 0, -900px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-UT_2X', );
$sos-2019-v-a-1-x-name: 'sos-2019-VA_1X';
$sos-2019-v-a-1-x-x: 400px;
$sos-2019-v-a-1-x-y: 1130px;
$sos-2019-v-a-1-x-offset-x: -400px;
$sos-2019-v-a-1-x-offset-y: -1130px;
$sos-2019-v-a-1-x-width: 70px;
$sos-2019-v-a-1-x-height: 70px;
$sos-2019-v-a-1-x-total-width: 1269px;
$sos-2019-v-a-1-x-total-height: 1200px;
$sos-2019-v-a-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-v-a-1-x: (400px, 1130px, -400px, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-VA_1X', );
$sos-2019-v-a-2-x-name: 'sos-2019-VA_2X';
$sos-2019-v-a-2-x-x: 150px;
$sos-2019-v-a-2-x-y: 900px;
$sos-2019-v-a-2-x-offset-x: -150px;
$sos-2019-v-a-2-x-offset-y: -900px;
$sos-2019-v-a-2-x-width: 140px;
$sos-2019-v-a-2-x-height: 140px;
$sos-2019-v-a-2-x-total-width: 1269px;
$sos-2019-v-a-2-x-total-height: 1200px;
$sos-2019-v-a-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-v-a-2-x: (150px, 900px, -150px, -900px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-VA_2X', );
$sos-2019-v-t-1-x-name: 'sos-2019-VT_1X';
$sos-2019-v-t-1-x-x: 480px;
$sos-2019-v-t-1-x-y: 1130px;
$sos-2019-v-t-1-x-offset-x: -480px;
$sos-2019-v-t-1-x-offset-y: -1130px;
$sos-2019-v-t-1-x-width: 70px;
$sos-2019-v-t-1-x-height: 70px;
$sos-2019-v-t-1-x-total-width: 1269px;
$sos-2019-v-t-1-x-total-height: 1200px;
$sos-2019-v-t-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-v-t-1-x: (480px, 1130px, -480px, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-VT_1X', );
$sos-2019-v-t-2-x-name: 'sos-2019-VT_2X';
$sos-2019-v-t-2-x-x: 300px;
$sos-2019-v-t-2-x-y: 900px;
$sos-2019-v-t-2-x-offset-x: -300px;
$sos-2019-v-t-2-x-offset-y: -900px;
$sos-2019-v-t-2-x-width: 140px;
$sos-2019-v-t-2-x-height: 140px;
$sos-2019-v-t-2-x-total-width: 1269px;
$sos-2019-v-t-2-x-total-height: 1200px;
$sos-2019-v-t-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-v-t-2-x: (300px, 900px, -300px, -900px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-VT_2X', );
$sos-2019-w-a-1-x-name: 'sos-2019-WA_1X';
$sos-2019-w-a-1-x-x: 560px;
$sos-2019-w-a-1-x-y: 1130px;
$sos-2019-w-a-1-x-offset-x: -560px;
$sos-2019-w-a-1-x-offset-y: -1130px;
$sos-2019-w-a-1-x-width: 70px;
$sos-2019-w-a-1-x-height: 70px;
$sos-2019-w-a-1-x-total-width: 1269px;
$sos-2019-w-a-1-x-total-height: 1200px;
$sos-2019-w-a-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-w-a-1-x: (560px, 1130px, -560px, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-WA_1X', );
$sos-2019-w-a-2-x-name: 'sos-2019-WA_2X';
$sos-2019-w-a-2-x-x: 450px;
$sos-2019-w-a-2-x-y: 900px;
$sos-2019-w-a-2-x-offset-x: -450px;
$sos-2019-w-a-2-x-offset-y: -900px;
$sos-2019-w-a-2-x-width: 140px;
$sos-2019-w-a-2-x-height: 140px;
$sos-2019-w-a-2-x-total-width: 1269px;
$sos-2019-w-a-2-x-total-height: 1200px;
$sos-2019-w-a-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-w-a-2-x: (450px, 900px, -450px, -900px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-WA_2X', );
$sos-2019-w-i-1-x-name: 'sos-2019-WI_1X';
$sos-2019-w-i-1-x-x: 640px;
$sos-2019-w-i-1-x-y: 1130px;
$sos-2019-w-i-1-x-offset-x: -640px;
$sos-2019-w-i-1-x-offset-y: -1130px;
$sos-2019-w-i-1-x-width: 70px;
$sos-2019-w-i-1-x-height: 70px;
$sos-2019-w-i-1-x-total-width: 1269px;
$sos-2019-w-i-1-x-total-height: 1200px;
$sos-2019-w-i-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-w-i-1-x: (640px, 1130px, -640px, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-WI_1X', );
$sos-2019-w-i-2-x-name: 'sos-2019-WI_2X';
$sos-2019-w-i-2-x-x: 600px;
$sos-2019-w-i-2-x-y: 900px;
$sos-2019-w-i-2-x-offset-x: -600px;
$sos-2019-w-i-2-x-offset-y: -900px;
$sos-2019-w-i-2-x-width: 140px;
$sos-2019-w-i-2-x-height: 140px;
$sos-2019-w-i-2-x-total-width: 1269px;
$sos-2019-w-i-2-x-total-height: 1200px;
$sos-2019-w-i-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-w-i-2-x: (600px, 900px, -600px, -900px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-WI_2X', );
$sos-2019-w-v-1-x-name: 'sos-2019-WV_1X';
$sos-2019-w-v-1-x-x: 720px;
$sos-2019-w-v-1-x-y: 1130px;
$sos-2019-w-v-1-x-offset-x: -720px;
$sos-2019-w-v-1-x-offset-y: -1130px;
$sos-2019-w-v-1-x-width: 70px;
$sos-2019-w-v-1-x-height: 70px;
$sos-2019-w-v-1-x-total-width: 1269px;
$sos-2019-w-v-1-x-total-height: 1200px;
$sos-2019-w-v-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-w-v-1-x: (720px, 1130px, -720px, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-WV_1X', );
$sos-2019-w-v-2-x-name: 'sos-2019-WV_2X';
$sos-2019-w-v-2-x-x: 750px;
$sos-2019-w-v-2-x-y: 900px;
$sos-2019-w-v-2-x-offset-x: -750px;
$sos-2019-w-v-2-x-offset-y: -900px;
$sos-2019-w-v-2-x-width: 140px;
$sos-2019-w-v-2-x-height: 140px;
$sos-2019-w-v-2-x-total-width: 1269px;
$sos-2019-w-v-2-x-total-height: 1200px;
$sos-2019-w-v-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-w-v-2-x: (750px, 900px, -750px, -900px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-WV_2X', );
$sos-2019-w-y-1-x-name: 'sos-2019-WY_1X';
$sos-2019-w-y-1-x-x: 800px;
$sos-2019-w-y-1-x-y: 1130px;
$sos-2019-w-y-1-x-offset-x: -800px;
$sos-2019-w-y-1-x-offset-y: -1130px;
$sos-2019-w-y-1-x-width: 70px;
$sos-2019-w-y-1-x-height: 70px;
$sos-2019-w-y-1-x-total-width: 1269px;
$sos-2019-w-y-1-x-total-height: 1200px;
$sos-2019-w-y-1-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-w-y-1-x: (800px, 1130px, -800px, -1130px, 70px, 70px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-WY_1X', );
$sos-2019-w-y-2-x-name: 'sos-2019-WY_2X';
$sos-2019-w-y-2-x-x: 900px;
$sos-2019-w-y-2-x-y: 900px;
$sos-2019-w-y-2-x-offset-x: -900px;
$sos-2019-w-y-2-x-offset-y: -900px;
$sos-2019-w-y-2-x-width: 140px;
$sos-2019-w-y-2-x-height: 140px;
$sos-2019-w-y-2-x-total-width: 1269px;
$sos-2019-w-y-2-x-total-height: 1200px;
$sos-2019-w-y-2-x-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$sos-2019-w-y-2-x: (900px, 900px, -900px, -900px, 140px, 140px, 1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', 'sos-2019-WY_2X', );
$spritesheet-width: 1269px;
$spritesheet-height: 1200px;
$spritesheet-image: 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png';
$spritesheet-sprites: ($sos-2019-a-k-1-x, $sos-2019-a-k-2-x, $sos-2019-a-l-1-x, $sos-2019-a-l-2-x, $sos-2019-a-r-1-x, $sos-2019-a-r-2-x, $sos-2019-a-z-1-x, $sos-2019-a-z-2-x, $sos-2019-c-a-1-x, $sos-2019-c-a-2-x, $sos-2019-c-o-1-x, $sos-2019-c-o-2-x, $sos-2019-c-t-1-x, $sos-2019-c-t-2-x, $sos-2019-d-e-1-x, $sos-2019-d-e-2-x, $sos-2019-f-l-1-x, $sos-2019-f-l-2-x, $sos-2019-g-a-1-x, $sos-2019-g-a-2-x, $sos-2019-h-i-1-x, $sos-2019-h-i-2-x, $sos-2019-i-a-1-x, $sos-2019-i-a-2-x, $sos-2019-i-d-1-x, $sos-2019-i-d-2-x, $sos-2019-i-l-1-x, $sos-2019-i-l-2-x, $sos-2019-i-n-1-x, $sos-2019-i-n-2-x, $sos-2019-k-s-1-x, $sos-2019-k-s-2-x, $sos-2019-k-y-1-x, $sos-2019-k-y-2-x, $sos-2019-l-a-1-x, $sos-2019-l-a-2-x, $sos-2019-m-a-1-x, $sos-2019-m-a-2-x, $sos-2019-m-d-1-x, $sos-2019-m-d-2-x, $sos-2019-m-e-1-x, $sos-2019-m-e-2-x, $sos-2019-m-i-1-x, $sos-2019-m-i-2-x, $sos-2019-m-n-1-x, $sos-2019-m-n-2-x, $sos-2019-m-o-1-x, $sos-2019-m-o-2-x, $sos-2019-m-s-1-x, $sos-2019-m-s-2-x, $sos-2019-m-t-1-x, $sos-2019-m-t-2-x, $sos-2019-n-c-1-x, $sos-2019-n-c-2-x, $sos-2019-n-d-1-x, $sos-2019-n-d-2-x, $sos-2019-n-e-1-x, $sos-2019-n-e-2-x, $sos-2019-n-h-1-x, $sos-2019-n-h-2-x, $sos-2019-n-j-1-x, $sos-2019-n-j-2-x, $sos-2019-n-m-1-x, $sos-2019-n-m-2-x, $sos-2019-n-v-1-x, $sos-2019-n-v-2-x, $sos-2019-n-y-1-x, $sos-2019-n-y-2-x, $sos-2019-o-h-1-x, $sos-2019-o-h-2-x, $sos-2019-o-k-1-x, $sos-2019-o-k-2-x, $sos-2019-o-r-1-x, $sos-2019-o-r-2-x, $sos-2019-p-a-1-x, $sos-2019-p-a-2-x, $sos-2019-r-i-1-x, $sos-2019-r-i-2-x, $sos-2019-s-c-1-x, $sos-2019-s-c-2-x, $sos-2019-s-d-1-x, $sos-2019-s-d-2-x, $sos-2019-t-n-1-x, $sos-2019-t-n-2-x, $sos-2019-t-x-1-x, $sos-2019-t-x-2-x, $sos-2019-u-t-1-x, $sos-2019-u-t-2-x, $sos-2019-v-a-1-x, $sos-2019-v-a-2-x, $sos-2019-v-t-1-x, $sos-2019-v-t-2-x, $sos-2019-w-a-1-x, $sos-2019-w-a-2-x, $sos-2019-w-i-1-x, $sos-2019-w-i-2-x, $sos-2019-w-v-1-x, $sos-2019-w-v-2-x, $sos-2019-w-y-1-x, $sos-2019-w-y-2-x, );
$spritesheet: (1269px, 1200px, 'https://esh-baloo-images.s3-us-west-1.amazonaws.com/sprite-7468cfb5cf650856c6da384283a5ed4a.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home
//   @include sprite-width($icon-home)
//
// .icon-email
//   @include sprite($icon-email)
//
// Example usage in HTML:
//   `display: block` sprite:
//   <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//   // CSS
//   .icon {
//     display: inline-block;
//   }
//
//   // HTML
//   <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5); }

@mixin sprite-height($sprite) {
  height: nth($sprite, 6); }

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y; }

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image}); }

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite); }

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SASS
//
// @include sprites($spritesheet-sprites)
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);

    .#{$sprite-name} {
      @include sprite($sprite); } } }
