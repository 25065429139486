@import '../../styles/mixins';
@import '../../styles/variables';


.Header {
  display: inline-flex;
  white-space: nowrap; }

.table {
  margin-bottom: 0;

  thead {
    th {
      border-bottom: 0; } }

  th,
  td {
    border-top: 0; }

  th > span {
    @include tablet-sm {
      white-space: normal; }
    white-space: nowrap; }

  .Table__header {
    border: 0;
    box-shadow: inset 0 0 0 $white, inset 0 -2px 0 $colorLightGray3;

    &--selected {
      box-shadow: inset 0 0 0 $white, inset 0 -2px 0 $colorSecondary3; } } }

.Table {
  &__title--container {
    background-color: $white;
    margin: 16px;
    position: sticky;
    top: 0; }

  &__title--text {
    display: inline-block; }

  &__title {
    color: $black;
    font-family: $fontRegular;
    font-size: 20px; }

  &__title--subtitle {
    @include tablet-sm {
      font-size: 14px; }
    @include mobile-sm {
      line-height: 18px; }
    color: $colorGray1;
    font-family: $fontRegular;
    font-size: 14px;
    line-height: 21px; }

  &__header {
    @include desktop-sm {
      font-size: 12px; }
    @include tablet-sm {
      font-size: 14px; }
    color: $black;
    font-family: $fontRegular;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
    padding: 8px; }

  &__header--selected {
    font-family: $fontBold;
    font-weight: 700; }

  &__row {
    font-size: 14px;
    font-weight: 400; }

  &__cell--light {
    font-weight: lighter; }

  &__cell--left {
    text-align: left; }

  &__cell--right {
    text-align: right; }

  &__cell--bold {
    font-family: $fontBold; }

  &__cell--center {
    text-align: center; }

  &__container {
    &__fadeout {
      background: linear-gradient($colorWhite-0-percent 0%, $colorWhite-100-percent 100%);
      bottom: -1.5em;
      height: 4em;
      position: sticky; } } }
