@import '../../../styles/variables';
@import '../../../styles/mixins';


.ChartLegend {
  @include mobile-sm {
    padding: 0 10px; }
  font-size: 10px;
  padding: 0 30px; }

.ChartLegend__series {
  display: inline-block;

  &__box {
    border-radius: 4px;
    display: inline-block;
    height: 12px;
    width: 12px; }

  &__label {
    display: inline-block;
    padding: 10px 20px 10px 5px; } }

.national {
  .ChartLegend__series__box {
    background-color: $colorChartColor1; } }

.state {
  .ChartLegend__series__box {
    background-color: $colorChartColor3; } }

.state-comparison {
  .ChartLegend__series__box {
    background-color: $colorChartColor2; } }
