@import '../../../styles/variables';
@import '../../../styles/mixins';


.MenuItem {
  border-radius: 10px;
  color: $black;
  font-family: $fontRegular;
  font-size: 14px;
  height: 30px;
  text-align: center;
  white-space: nowrap;
  width: 110px;

  > .MenuItem__arrow {
    margin-left: 5px;
    padding-top: 4px; }

  &:hover {
    @include menu-title-underscore($colorSecondary3-60-percent);

    .MenuItem__arrow {
      transform: rotate(180deg); } }

  &--selected {
    @include menu-title-underscore($colorSecondary3); }

  &__icon {
    height: 24px;
    object-fit: contain;
    padding-right: 5px; } }

.MobileMenuItem {
  color: $colorGray1;
  display: table;
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 0 5px 5px;
  text-align: left;
  width: 100%;
  z-index: 5;

  > .MenuItem__icon {
    padding-right: 13px;
    vertical-align: bottom; }

  &--selected {
    color: $colorSecondary3; }

  > .MenuItem__arrow {
    float: right;
    margin-right: 24px; } }

.toggleDropdown > .MobileMenuItem > .MenuItem__arrow {
  transform: rotate(180deg); }

.MobileMenu__background {
  background-color: $colorBlack-60-percent;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; }
