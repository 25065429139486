@import '../../../styles/mixins';
@import '../../../styles/variables';


.Filter__label {
  @include tablet-md {
    display: none; }
  display: flex;
  flex-direction: column; }

.Filter__label-text {
  display: inline;
  font-family: $fontBold; }

.Filter__name {
  @include tablet-md {
    display: none; }
  align-items: center;
  border: solid 1px $colorPrimary-60-percent;
  border-radius: 4px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 14px 0 16px;
  user-select: none;

  &:hover {
    cursor: pointer; } }


.Filter__name--closed {
  background-color: $white;
  color: $black; }

.Filter__name--open {
  background-color: $colorPrimary-60-percent;
  color: $white; }

.Filter__toggle {
  margin-left: 5px; }
