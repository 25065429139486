@import '../../../styles/variables';

.PopupFooter {
  background-color: $colorSecondary3-20-percent;
  padding: 10px;
  text-align: center;

  &__icon {
    height: 18px;
    vertical-align: text-bottom;
    width: 18px; }

  &__link {
    color: $black;
    font-family: $fontBold;
    font-size: 14px;

    &:hover {
      color: $black;
      text-decoration: underline; } } }
