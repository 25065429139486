@import '../../styles/variables';
@import '../../styles/mixins';


.NewsletterSignupConfirmation {
  background: $colorBlack-80-percent;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 998;

  &__container {
    @include desktop-sm {
      bottom: 40px;
      left: 40px;
      right: 40px;
      top: 80px; }
    @include tablet-md {
      bottom: 40px;
      left: 40px;
      right: 40px;
      top: 40px; }
    @include mobile-sm {
      bottom: auto;
      box-sizing: border-box;
      height: 100%;
      left: auto;
      max-height: auto;
      max-width: 100%;
      min-height: auto;
      overflow: auto;
      position: static;
      right: auto;
      top: auto; }
    background-color: $white;
    bottom: 100px;
    left: 100px;
    min-height: calc(100vh - 15rem);
    padding: 2rem 2rem 4rem;
    position: absolute;
    right: 100px;
    top: 150px; }

  &__logo-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 29.125rem;
    padding-bottom: 0; }

  &__logo {
    display: block;
    margin: 0 auto 1.5rem 0;
    max-height: 7.5rem;
    max-width: 100%; }

  &__form-container {
    background: $white;
    margin-left: auto;
    margin-right: auto;
    max-width: 35.25rem;
    position: relative;

    p {
      border: 0;
      border-bottom: 1px solid $newsletterGray;
      color: $newsletterParagraph;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 16px;
      margin: 0 0 2rem;
      padding: 0 0 1.5rem;
      white-space: pre-wrap; }

    .form-section {
      margin-bottom: 1.5rem;

      &.top-divider {
        border-top: 1px solid $newsletterGray;
        padding-top: 1.5rem; }

      .textbox-wrapper {
        margin-bottom: 1rem; }

      .textbox-label {
        color: $newsletterDarkGray;
        display: block;
        font-family: Helvetica, Arial, sans-serif;
        font-size: .875rem;
        font-weight: 400;
        margin-bottom: .5rem; }

      .textbox {
        appearance: none;
        background-color: $white;
        border: 0;
        border-radius: .1875rem;
        box-shadow: $newsletterTextboxShadow 0 2px 0 0 inset, $newsletterGray 0 0 0 1px inset, $white 0 1px 0 0;
        box-sizing: border-box;
        color: $newsletterParagraph;
        font-size: .875rem;
        line-height: 1.5;
        min-height: 2.8125rem;
        outline: 0;
        padding: .75rem 1rem;
        transition: box-shadow .2s ease 0s;
        width: 100%;

        &:focus {
          box-shadow: transparent 0 0 0 0 inset, $newsletterTextboxFocusInner 0 0 0 1px inset, $newsletterTextboxFocusOuter 0 0 0 2px; } }

      .checkbox-wrapper {
        align-items: flex-start;
        display: flex; }

      .checkbox-label {
        color: $newsletterParagraph;
        font-family: Helvetica, Arial, sans-serif;
        font-size: .875rem;
        overflow-wrap: break-word;
        padding-left: .5rem;
        word-break: break-word; }

      .checkbox {
        flex-shrink: 0;
        height: 1rem;
        position: relative;
        top: .08rem;
        width: 1rem; } }

    .newsletterbutton {
      @include mobile-sm {
        display: block;
        margin: 2rem 0 0;
        width: 100%; }
      background-color: $colorSecondary3;
      border: 0;
      border-radius: 4px;
      color: $white;
      font-family: $fontBold;
      margin-top: 1rem;
      padding: .75rem 1rem;

      &.cancel {
        background-color: $colorPrimary-60-percent;
        position: absolute;
        right: 0; }

      &:hover {
        box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-12-percent, 0 3px 4px 0 $colorBlack-14-percent; } } } }
