@import '../../styles/mixins';
@import '../../styles/variables';


.ErrorPage {
  @include mobile-sm {
    min-height: 400px; }
  @include desktop-sm {
    background-size: 35vw, 65vw;
    padding-top: calc(20vh + 16px); }
  background-color: $white;
  background-image: url('../../assets/top-graphic-wave.svg'), url('../../assets/bottom-graphic-students.svg');
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 35vw, auto 75vh;
  height: calc(100vh - 65px);
  margin-top: $navbar-height;
  min-height: 550px;
  padding: calc(30vh + 16px) 10vw 0;

  &__title {
    @include mobile-sm {
      font-size: 88px; }
    color: $black;
    font-family: $fontBold;
    font-size: 128px;
    letter-spacing: 6.92px; }

  &__subtitle {
    @include mobile-sm {
      font-size: 16px; }
    color: $black;
    font-family: $fontRegular;
    line-height: 1.25; }

  &__button {
    @include mobile-sm {
      margin-top: 16px; }
    background-color: $colorSecondary3;
    border: 0;
    border-radius: 8px;
    color: $white;
    font-family: $fontBold;
    font-size: 16px;
    height: 40px;
    line-height: 1.5;
    margin-top: 37px;
    text-align: center;
    width: 208px;

    &:hover {
      box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-12-percent, 0 3px 4px 0 $colorBlack-14-percent; } } }

.NotFound {
  &__error {
    display: none; }

  &__subtitle {
    font-size: 28px;
    max-width: 592px; } }

.ServerError {
  @include mobile-sm {
    padding: calc(10vh + 16px) 7vw 0; }

  &__error {
    color: $colorGray1;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2.4px;
    line-height: 1;
    text-transform: uppercase; }

  &__subtitle {
    @include tablet-md {
      max-width: 100%; }
    @include mobile-sm {
      font-size: 16px; }
    font-size: 32px;
    max-width: 45%; } }
