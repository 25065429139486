@import '../../../styles/mixins';
@import '../../../styles/variables';


.ContractFilter {
  @include tablet-md {
    justify-content: space-between; }
  @include mobile-sm {
    min-width: 100%; }
  display: flex;
  flex-flow: row nowrap;
  min-width: 300px; }
