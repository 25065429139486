@import '../../../styles/variables';
@import '../../../styles/mixins';


.Navbar__link--state {
  &:hover > .MenuItem > .StateMenuDropdown {
    @include ie11-styles {
      position: absolute;
      z-index: 100; }
    display: block; } }

.StateMenuDropdown--mobile {
  display: block;
  margin-left: 16px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms ease-in-out; }

.StateMenuDropdown {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-12-percent, 0 3px 4px 0 $colorBlack-14-percent;
  display: none;
  flex-wrap: wrap;
  margin-left: -40px;
  position: fixed;
  top: 50px;
  width: 200px;
  z-index: 1; }

.toggleDropdown > .MobileMenuItem > .StateMenuDropdown--mobile {
  max-height: 600px; }
