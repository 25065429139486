@import '../../styles/variables';
@import '../../styles/mixins';


.StateButtonLink {
  @include desktop-sm {
    min-width: 100%; }
  align-items: center;
  border: solid 1px $colorSecondary3;
  border-radius: 8px;
  color: $black;
  display: flex;
  flex: 0 1 250px;
  font-family: $fontBold;
  font-size: 14px;
  justify-content: space-around;
  line-height: 1.5;
  margin: 0 auto;
  min-width: 250px;
  padding: 8px 16px;
  text-align: center;
  width: 100%;

  &:hover {
    background-color: $colorSecondary3;
    color: $white;
    cursor: pointer;
    text-decoration: none; } }
