@import '../../styles/variables';
@import '../../styles/mixins';


// sass-lint:disable no-vendor-prefixes
.GovernorQuoteBox {
  @include ie11-styles {
    -ms-grid-column: 6;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    display: block;
    margin: 7px;
    width: 20.5vw; }
  @include tablet-md {
    @include ie11-styles {
      -ms-grid-column: 5;
      -ms-grid-column-span: 1;
      -ms-grid-row: 1;
      width: 41vw; }
    grid-column: 5 / span 2; }
  @include mobile-sm {
    height: fit-content;
    margin: 8px 0;
    width: 100vw; }
  @include tablet-xs {
    @include ie11-styles {
      width: 42vw; }
    margin: 15px 0; }
  background-color: $white;
  box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-12-percent, 0 3px 4px 0 $colorBlack-14-percent;
  flex: 1 1 auto;
  grid-column: 7 / span 2;
  grid-row: 1;
  padding-bottom: 8px;

  &__expand {
    @include ie11-styles {
      -ms-grid-column: 4;
      -ms-grid-column-span: 2;
      width: 25vw; }
    @include tablet-md {
      @include ie11-styles {
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        -ms-grid-row: 2;
        height: auto;
        width: 36vw; }
      grid-column: 1 / span 2;
      grid-row: 2; } }

  &__header {
    @include tablet-sm {
      font-size: 14px; }
    align-items: center;
    background-color: $colorSecondary3-10-percent;
    color: $black;
    display: flex;
    font-family: $fontRegular;
    font-size: 20px;
    line-height: 1.5;
    padding: 8px; }

  &__photo {
    border-radius: 50%;
    flex-shrink: 0;
    height: 56px;
    margin: 4px 8px;
    width: 56px; }


  &__quote {
    color: $black;
    display: block;
    font-family: $fontRegular;
    font-size: 14px;
    line-height: 1.5;
    margin: 16px; } }
