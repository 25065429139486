@import '../../../styles/variables';

.CircuitSize {
  &__units {
    border-radius: 0 4px 4px 0;
    height: 100%;
    outline: none;
    overflow: hidden;
    position: relative; }

  &__unit-container {
    background-color: $white;
    border: 0;
    color: $black;
    height: 100%;
    outline: none;
    padding-top: 5px;
    position: relative;
    width: 100%; }

  &__unit {
    padding: 0 10px; }

  &__input {
    .InputWithUnits__input {
      min-width: 55px;
      width: 5em; } } }
