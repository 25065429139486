@import '../../../styles/variables';
@import '../../../styles/mixins';


.Filter {
  @include tablet-md {
    margin-right: 8px;
    margin-top: 8px; }
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.5;
  margin-right: 16px;
  position: relative; }

.Filter--purpose {
  @include tablet-md {
    margin-right: 0; } }

.FilterPane__filters {
  .Filter__hidden {
    @include tablet-md {
      display: none; } } }
