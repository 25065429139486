@import '../../../styles/variables';
@import '../../../styles/mixins';


// sass-lint:disable no-vendor-prefixes
.CostOverTime {
  @include ie11-styles {
    -ms-grid-column: 3;
    -ms-grid-column-span: 4;
    -ms-grid-row: 2;
    margin: 7px;
    width: 55vw; }
  @include tablet-md {
    @include ie11-styles {
      -ms-grid-column: 1;
      -ms-grid-column-span: 4;
      -ms-grid-row: 3;
      width: auto; }
    grid-column: 1 / span 4;
    grid-row: 3;
    margin-bottom: 20px; }
  @include tablet-sm {
    align-items: center; }
  @include tablet-xs {
    margin: 15px 0; }
  background-color: $white;
  box-shadow: 0 1px 8px 0 $colorBlack-20-percent, 0 3px 3px -2px $colorBlack-12-percent, 0 3px 4px 0 $colorBlack-14-percent;
  display: flex;
  flex-flow: column;
  grid-column: 4 / span 3;
  grid-row: 2;

  &__expand {
    @include tablet-md {
      @include ie11-styles {
        -ms-grid-column: 1;
        -ms-grid-column-span: 6;
        width: auto; }
      grid-column-end: span 6; }
    grid-column-end: span 5; }

  &__chart {
    @include ie11-styles {
      width: 51vw; }
    @include tablet-md {
      @include ie11-styles {
        height: 35vh;
        width: 85vw; }
      height: 20vh; }
    @include mobile-sm {
      padding: 0 1em; }
    height: 25vh;
    padding: 0 2em;
    position: relative;
    width: 100%; }

  &__footnote {
    @include mobile-sm {
      padding: 20px 10px 10px; }
    align-self: flex-start;
    font-size: 12px;
    padding: 20px 30px 10px;

    &__pdf-link {
      &:hover {
        cursor: pointer; } }

    a {
      color: $colorSecondary3; } }

  .ChartLegend {
    align-self: flex-start; } }
