@import '../../../styles/mixins';
@import '../../../styles/variables';


.Partners {
  // sass-lint:disable no-vendor-prefixes
  @include ie11-styles {
    -ms-grid-column-span: 3;
    -ms-grid-row: 5;
    margin: 7px; }
  @include tablet-sm {
    grid-row: 5;
    margin-top: 0; }
  @include mobile-sm {
    margin-bottom: 35px;
    padding: 0 15px; }
  grid-column: 1 / 3;
  grid-row: 5;
  margin-top: 25px;
  text-align: center;

  &__text {
    @include ie11-styles {
      @include tablet-md {
        font-size: 18px;
        margin-top: 0; }
      margin-top: 1.4vw; }
    @include tablet-sm {
      font-size: 16px;
      margin-top: 16px; }
    @include mobile-sm {
      margin: 24px 0 16px; }
    font-size: 24px;
    line-height: 1.33; }

  &--logos {
    @include tablet-sm {
      margin-top: 10px; }
    @include mobile-sm {
      display: grid;
      grid-column-gap: 2%;
      grid-template-columns: 49% 49%;
      justify-items: center; }
    margin-top: 16px; }

  &--logo {
    @include tablet-sm {
      height: 45px;
      margin: 15px 20px 15px 0; }
    @include mobile-sm {
      height: 5vh;
      margin: 15px 0; }
    height: 40px;
    margin: 10px 13px; } }
